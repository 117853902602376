import React from "react";
import { Link } from "react-router-dom";
import mergeClassNames from "classnames";
import BulmaCSS from "../../css/bulma.module.sass";
import Styles from "../../css/styles.module.css";
import Section from "./Section";
import { getFixedUrl } from "../../utils";
import { PureTagList } from "./TagsList";

type Item = {
  authority: string;
  authorityWebSite?: string;
  authorityMeta?: string;
  workLocation?: string;
  rightSide?: React.ReactNode;
  title: string;
  description?: string;
  descriptionTags?: string[];
  bulletWorkExplanations?: string[];
  isThisItemVisible: boolean;
};

type CommonListProps = {
  items?: Item[];
  visibleItemCount?: number;
  title?: string;
  description?: string;
  icon?: any; // Replace with a more specific type if known
  detailed_page_path?: string;
  xtraClassName?: string;
  bulletWorkExplanations?: string[];
  detailedPagePath?: string
};

export function CommonList(props: CommonListProps) {
  const {
    title,
    description,
    icon,
    detailedPagePath,
    items = [],
    xtraClassName,
    visibleItemCount = 5,
  } = props;

  return (
    <Section
      xtraClassName={xtraClassName}
      title={title}
      content={description}
      icon={icon}
    >
      <div
        className={mergeClassNames(
          BulmaCSS.container,
          BulmaCSS["is-medium"],
          Styles.commonListContainer
        )}
      >
        {items.slice(0, visibleItemCount+1).map((item, index) => {
          const {
            authority,
            authorityWebSite,
            authorityMeta,
            workLocation,
            rightSide,
            title,
            description,
            descriptionTags,
            bulletWorkExplanations,
            isThisItemVisible,
          } = item;
          return ( isThisItemVisible ?
            <div
              key={index}
              className={mergeClassNames(
                BulmaCSS.content,
                Styles.avoidBreakingOnPrint
              )}
            >
              <div
                className={mergeClassNames(
                  BulmaCSS.level,
                  BulmaCSS["is-marginless"],
                  BulmaCSS["is-paddingless"]
                )}
              >
                <h5
                  className={mergeClassNames(
                    BulmaCSS.title,
                    BulmaCSS["is-marginless"],
                    BulmaCSS["level-left"],
                    BulmaCSS["is-size-5"]
                  )}
                >
                  {title}
                </h5>
                <span className={mergeClassNames(BulmaCSS["level-right"])}>
                  {rightSide}
                </span>
              </div>
              <h6
                className={mergeClassNames(
                  BulmaCSS.subtitle,
                  Styles.companyTitle,
                  BulmaCSS["is-size-6"]
                )}
              >
                {authorityWebSite ? (
                  <a
                    href={getFixedUrl(authorityWebSite)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {authority}
                  </a>
                ) : (
                  authority
                )}{" "}
                {workLocation ? `/ ${workLocation}` : null}
              </h6>
              {authorityMeta && (
                <span
                  className={mergeClassNames(Styles.companyMeta)}
                >{`(${authorityMeta})`}</span>
              )}
              {/* Either bulletWorkExplanations or description is displayed */}
              {bulletWorkExplanations ? (
                <div
                  className={mergeClassNames(
                    BulmaCSS.content,
                    Styles.avoidBreakingOnPrint
                  )}
                >
                  <ul>
                    {bulletWorkExplanations.map((exp, expIndex) => (
                      <li key={expIndex}>{exp}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                description && (
                  <p className={Styles.description}>{description}</p>
                )
              )}
              {descriptionTags && (
                <p>
                  <h6>Technology Stack</h6>
                  <PureTagList tags={descriptionTags} tagClass="is-dark" />
                </p>
              )}
            </div>
          : null);
        })}
        {
          detailedPagePath ? (
            <Link to={detailedPagePath}>
              See '{title}' page for more details.
            </Link> 
          ): null
          // Uncomment if needed
          // <a href={`https://takanabe.tokyo${detailed_page_path}`}>See '{title}' page for more details.</a>
        }
      </div>
    </Section>
  );
}
