import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import mergeClassNames from 'classnames';
import BulmaCSS from "../../css/bulma.module.sass";
import Styles from '../../css/styles.module.css';

import Profile, { ContactItem } from './Profile';
import Education from '../Education';
import ProfessionalExperience from '../ProfessionalExperience';
import SideJobExperience from '../SideJobExperience';
import { TopPage } from '../TopPage';
import Top from '../Top';

type Contact = {
  type: 'email' | 'website' | 'phone' | 'location' | 'linkedin' | 'github';
  value: string;
};

type Language = {
  value: string;
  level: string;
};

export type CVDataSection = {
  type: string;
  title?: string;
  isThisSectionVisible: boolean;
  description?: string;
  icon?: string
  detailedPagePath?: string;
  visibleItemCount?: number;
  // TODO: Define appropriate types
  items?: any[]
  groups?: any[]
  bulletWorkExplanations?: string[]
};

type PersonalData = {
  languages?: Language[];
  interests?: string[];
  
  name: string;
  title: string;
  focus?: string;
  contacts: ContactItem[];
  image?: string;
  top_url?: string;
  isResume?: boolean;
};

export type CVRootProps = {
  sideJobVisibility?: boolean;
  personalData: PersonalData;
  sections: CVDataSection[];
  branding?: boolean;
};

export const CVRoot: React.FC<CVRootProps> = ({
  personalData,
  sections,
  sideJobVisibility = false,
  branding = true
}) => {
  return (
    <Router>
      <section className={mergeClassNames(Styles.appContainer, BulmaCSS.section)}>
        <main className={BulmaCSS.container}>
          <div className={mergeClassNames(Styles.cvContainer, BulmaCSS.box)}>
            {/* Profile defines the top section displaying common info
            such as contact and  SNS links */}
            <Profile {...personalData} />
            {/* Arrange components used for detail pages. Not for the top page.
            The order by Route does not affect the actual order of contents on the page.
            These are just maps between request paths and Components to be displayed. */}
            <Switch>
              <Route path="/about">
                <Top sections={sections} sideJobSectionVisibility={sideJobVisibility}/>
              </Route>
              <Route path="/education">
                <Education sections={sections} />
              </Route>
              <Route path="/professional-experience">
                <ProfessionalExperience sections={sections} />
              </Route>
              <Route path="/sidejobs">
                <SideJobExperience sections={sections} />
              </Route>
              <Route path="/public-exposure">
                <Top sections={sections} sideJobSectionVisibility={sideJobVisibility}/>
              </Route>
              {/* This default path shows the top page. */}
              <Route path="/">
                <TopPage sections={sections} sideJobVisibility={sideJobVisibility}/>
              </Route>
            </Switch>
          </div>
        </main>
      </section>
    </Router>
  );
};
