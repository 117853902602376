import React from 'react';
import mergeClassNames from 'classnames';
import BulmaCSS from '../../css/bulma.module.sass';
import Styles from '../../css/styles.module.css';
import Section from './Section';
import { getFixedUrl } from '../../utils';

type Project = {
  projectUrl?: string;
  title: string;
  description?: string;
  publishedAt?: string;
};

type Group = {
  sectionHeader: string;
  description?: string;
  items: Project[];
};

type PublicExposureListProps = {
  groups?: Group[];
  title?: string;
  description?: string;
  icon?: any; // Replace with a more specific type if known
};

export function PublicExposureList(props: PublicExposureListProps) {
  const { title, description, icon, groups } = props;

  return (
    <Section title={title} content={description} icon={icon}>
      <div className={mergeClassNames(BulmaCSS.container, BulmaCSS['is-medium'])}>
        {groups?.map((group, groupIndex) => {
          const { sectionHeader, description, items } = group;
          return (
            <div key={groupIndex} className={mergeClassNames(BulmaCSS.container, 'project-group-external', Styles.avoidBreakingOnPrint, Styles['project-group'])}>
              <div className={mergeClassNames(BulmaCSS.content)}>
                <div className={mergeClassNames(BulmaCSS['is-marginless'])}>
                  <h5 className={mergeClassNames(BulmaCSS.title, Styles.companyTitle, BulmaCSS['is-size-5'])}>
                    {sectionHeader}
                  </h5>
                  {description && <span className={mergeClassNames(Styles.projectMeta)}> - {description}</span>}
                </div>
              </div>
              <hr className={Styles.divider} />
              <div className={BulmaCSS.row}>
                {items.map((project, projectIndex) => {
                  const { projectUrl, title, description, publishedAt } = project;
                  return (
                    <div key={projectIndex} className={mergeClassNames(BulmaCSS['u-full-width'], Styles.avoidBreakingOnPrint)}>
                      <h6 className={mergeClassNames(Styles.projectTitle, BulmaCSS['is-size-6'])}>
                        {projectUrl ? <a href={getFixedUrl(projectUrl)} target='_blank' rel="noreferrer">{title}</a> : title}, {publishedAt ? publishedAt : null}
                      </h6>
                      {description ? <div style={{ margin: 10 }}>{description}</div> : null}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}
