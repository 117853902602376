import { DetailedCommonList } from './Base/DetailedCommonList';

const ProfessionalExperience = ({ sections }) => {
  return (
    sections.map((sectionDetails) => {
      if (sectionDetails["title"] === "Professional experience") {

        const convertedItems = (sectionDetails.items || []).map((item) => {
          const { company, companyWebSite, companyMeta, datesBetween, ...rest } = item;
          return {
            authority: company,
            authorityWebSite: companyWebSite,
            authorityMeta: companyMeta,
            rightSide: datesBetween,
            ...rest
          };
        })

        return (
          <DetailedCommonList
            {...sectionDetails}
            items={convertedItems}
          />
        )
      }
    })
  );
};

export default ProfessionalExperience;
