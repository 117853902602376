import { DetailedCommonList }  from './Base/DetailedCommonList';
import { EXPERIENCE_LIST_TITLE_SIDE_JOB } from './TopPage'

const SideJobExperience = ({ sections }) => {
  return (
    sections.map((sectionDetails) => {
      if (sectionDetails["title"] === EXPERIENCE_LIST_TITLE_SIDE_JOB) {

        const convertedItems = (sectionDetails.items || []).map((item) => {
          const { company, companyWebSite, companyMeta, datesBetween, ...rest } = item;
          return {
            authority: company,
            authorityWebSite: companyWebSite,
            authorityMeta: companyMeta,
            rightSide: datesBetween,
            ...rest
          };
        })

        return (
          <DetailedCommonList
            {...sectionDetails}
            items={convertedItems}
          />
        )
      }
    })
  );
};

export default SideJobExperience;
