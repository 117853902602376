import { CommonList } from './CommonList';

type ExperienceItem = {
  company: string;
  companyWebSite?: string;
  companyMeta?: string;
  datesBetween?: string;
  // Add other properties that might be in ...rest
};

type ExperiencesListProps = {
  // isThisSectionVisible?: boolean;
  // items?: ExperienceItem[];
  items?: any[];
  title?: string;
  description?: string;
  icon?: any; // Replace with a more specific type if known
  detailedPagePath?: string
  visibleItemCount?: number
};

export function ExperiencesList(props: ExperiencesListProps) {
  const convertedItems = (props.items || []).map((item) => {
    const { company, companyWebSite, companyMeta, datesBetween, ...rest } = item;
    return {
      authority: company,
      authorityWebSite: companyWebSite,
      authorityMeta: companyMeta,
      rightSide: datesBetween,
      ...rest
    };
  });

  return (
    <CommonList
      {...props}
      items={convertedItems}
    />
  );
}
