import Section from './Section';

type AboutMeProps = {
  title?: string;
  description?: string;
  icon?: any; // Specify a more detailed type if available
};

export function AboutMe({ title, description, icon }: AboutMeProps) {
  return (
    <Section
      title={title}
      content={description}
      icon={icon}
    />
  );
}
