import React from 'react';

// CV components
import Section from './Base/Section'
import { ExperiencesList } from './Base/ExperiencesList'
// import SideJobsList from './Base/ExperiencesList'
import { PublicExposureList }  from './Base/PublicExposureList'
import TagsList from './Base/TagsList'
import { CommonList } from './Base/CommonList'
import { AboutMe } from './Base/AboutMe';
import AboutThisCV from './Base/AboutThisCV';
import { CalloutGuideForMoreInfo } from './Base/CalloutGuideForMoreInfo'
import { TYPE_CALLOUT_GUIDE_FOR_MORE_INFO } from "./TopPage";

const componnentMap = {
  'experiences-list': ExperiencesList,
  'about-me': AboutMe, //done
  [TYPE_CALLOUT_GUIDE_FOR_MORE_INFO]: CalloutGuideForMoreInfo,
  'about-this-cv': AboutThisCV,
  'projects-list': PublicExposureList,
  // 'sidejobs-list': SideJobsList,
  'tag-list': TagsList,
  'common-list': CommonList
};

const Top = ({ sideJobSectionVisibility, sections }) => {
  return (
    sections.map((sectionDetails) => {
      const { type } = sectionDetails;
      const Comp = componnentMap[type] || Section; // Fallback to section for any case.
      return (
        // sectionDetails.title !== 'Side jobs'
        //   ?
            <div>
              <Comp {...sectionDetails} />
            </div>// : sideJobSectionVisibility
          // ?
          //   <div>
          //     <Comp {...sectionDetails} sideJobSectionVisibility={sideJobSectionVisibility} />
          //   </div>
          // : null
      )
    })
  );
};

export default Top;
