// CV components
import { ExperiencesList } from "./Base/ExperiencesList";
import { PublicExposureList } from "./Base/PublicExposureList";
import { AboutMe } from "./Base/AboutMe";
import { CalloutGuideForMoreInfo } from "./Base/CalloutGuideForMoreInfo";
import { CVRootProps } from "./Base/CVRoot";
import { CommonList } from "./Base/CommonList";

type Props = Omit<CVRootProps, "branding" | "personalData">;

// These match with the value of the type field in the section in data.ts
export const TYPE_CALLOUT_GUIDE_FOR_MORE_INFO = "callout-guide-for-more-info";
export const TYPE_ABOUT_ME = "about-me";
export const DATA_TYPE_PUBLIC_EXPOSURE = "public-exposure";
// common list defs
export const DATA_TYPE_COMMON_LIST = "common-list";
export const COMMON_LIST_TITLE_LANGUAGES = "Languages";
export const COMMON_LIST_TITLE_EDUCATION = "Education";

// experience list defs
export const DATA_TYPE_EXPERIENCE_LIST = "experiences-list";
export const EXPERIENCE_LIST_TITLE_PROFESSIONAL_EXP = "Professional experience";
export const EXPERIENCE_LIST_TITLE_INTERNSHIP = "Internship";
export const EXPERIENCE_LIST_TITLE_SIDE_JOB = "Side jobs";

export function TopPage({ sideJobVisibility, sections }: Props) {
  const aboutMeProps = sections.find(
    (section) => section.type === TYPE_ABOUT_ME
  );
  const calloutProps = sections.find(
    (section) => section.type === TYPE_CALLOUT_GUIDE_FOR_MORE_INFO
  );
  const publicExposureProps = sections.find(
    (section) => section.type === DATA_TYPE_PUBLIC_EXPOSURE
  );

  const educationListProps = sections.find(
    (section) =>
      section.type === DATA_TYPE_COMMON_LIST &&
      section.title === COMMON_LIST_TITLE_EDUCATION
  );
  const languageListProps = sections.find(
    (section) =>
      section.type === DATA_TYPE_COMMON_LIST &&
      section.title === COMMON_LIST_TITLE_LANGUAGES
  );

  const professionalExpListProps = sections.find(
    (section) =>
      section.type === DATA_TYPE_EXPERIENCE_LIST &&
      section.title === EXPERIENCE_LIST_TITLE_PROFESSIONAL_EXP
  );

  const sideJobExpListProps = sections.find(
    (section) =>
      section.type === DATA_TYPE_EXPERIENCE_LIST &&
      section.title === EXPERIENCE_LIST_TITLE_SIDE_JOB
  );

  const internExpListProps = sections.find(
    (section) =>
      section.type === DATA_TYPE_EXPERIENCE_LIST &&
      section.title === EXPERIENCE_LIST_TITLE_INTERNSHIP
  );

  return (
    <>
      {/* Callout Guide for More information */}
      {calloutProps?.isThisSectionVisible ? <CalloutGuideForMoreInfo /> : null}
      {/* About me */}
      {aboutMeProps?.isThisSectionVisible ? (
        <AboutMe
          title={aboutMeProps.title}
          description={aboutMeProps.description}
          icon={aboutMeProps.icon}
        />
      ) : null}
      {/* Professional experience */}
      {professionalExpListProps?.isThisSectionVisible ? (
        <ExperiencesList
          items={professionalExpListProps?.items}
          title={professionalExpListProps.title}
          description={professionalExpListProps.description}
          icon={professionalExpListProps.icon}
          visibleItemCount={professionalExpListProps.visibleItemCount}
          detailedPagePath={professionalExpListProps.detailedPagePath}
        />
      ) : null}
      {/* Side job experience */}
      {sideJobExpListProps?.isThisSectionVisible ? (
        <ExperiencesList
          items={sideJobExpListProps?.items}
          title={sideJobExpListProps.title}
          description={sideJobExpListProps.description}
          icon={sideJobExpListProps.icon}
          detailedPagePath={sideJobExpListProps.detailedPagePath}
        />
      ) : null}
      {/* Public Exposure */}
      {publicExposureProps?.isThisSectionVisible ? (
        <PublicExposureList
          title={publicExposureProps?.title}
          description={publicExposureProps?.description}
          icon={publicExposureProps?.icon}
          groups={publicExposureProps?.groups}
        />
      ) : null}
      {/* Common list(Education) */}
      {educationListProps?.isThisSectionVisible ? (
        <CommonList
          items={educationListProps.items}
          visibleItemCount={educationListProps.visibleItemCount}
          title={educationListProps.title}
          description={educationListProps.description}
          bulletWorkExplanations={educationListProps.bulletWorkExplanations}
          icon={educationListProps.icon}
          detailedPagePath={educationListProps.detailedPagePath}
        />
      ) : null}
      { /* Internship */ }
      {internExpListProps?.isThisSectionVisible ? (
        <ExperiencesList
          title={internExpListProps?.title}
          description={internExpListProps?.description}
          icon={internExpListProps?.icon}
          items={internExpListProps?.items}
        />
      ) : null}
      {/* Common list (Languages) */}
      {languageListProps?.isThisSectionVisible ? (
        <CommonList
          items={languageListProps.items}
          visibleItemCount={languageListProps.visibleItemCount}
          title={languageListProps.title}
          description={languageListProps.description}
          bulletWorkExplanations={languageListProps.bulletWorkExplanations}
          icon={languageListProps.icon}
        />
      ) : null}
    </>
  );
}
