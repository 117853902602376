import { COMMON_LIST_TITLE_EDUCATION } from "./TopPage"

// CV components
import { DetailedCommonList } from './Base/DetailedCommonList'

const Education = ({ sections }) => {
  return (
    sections.map((sectionDetails) => {
      if (sectionDetails["title"] === COMMON_LIST_TITLE_EDUCATION) {
        return (
          <DetailedCommonList {...sectionDetails} />
        )
      }
    })
  );
};
export default Education;
