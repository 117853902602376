import classnames from 'classnames';
import B from '../../css/bulma.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faTwitter, faSpeakerDeck } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faGlobeAmericas, faExclamation, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { formatAnchorValue } from '../../utils';
import Styles from '../../css/styles.module.css';

const iconMap = {
  email: faEnvelope,
  phone: faPhone,
  location: faMapMarkerAlt,
  website: faGlobeAmericas,
  linkedin: faLinkedin,
  github: faGithub,
  twitter: faTwitter,
  speakerdeck: faSpeakerDeck,
};

export type ContactItem = {
  type: keyof typeof iconMap;
  value: string;
};

type ContactListProps = {
  items: ContactItem[];
};

function ContactList(props: ContactListProps) {
  return (
    <div className={classnames(Styles.contactListContainer, B['has-text-white'])}>
      {props.items.map((item, index) => {
        const icon = iconMap[item.type] || faExclamation;
        return (
          <div key={index} className={Styles.contactListItem}>
            <FontAwesomeIcon icon={icon} />
            <a className={Styles.contactListItemContent} href={formatAnchorValue(item.type, item.value)} target='_blank' rel="noreferrer">
              {item.value}
            </a>
          </div>
        );
      })}
    </div>
  );
}

type ProfileProps = {
  name: string;
  title: string;
  focus?: string;
  contacts: ContactItem[];
  image?: string;
  top_url?: string;
  isResume?: boolean;
};

function Profile(props: ProfileProps) {
  return (
    <div className={classnames(B.container, B.box, B.hero, B['is-small'], B['is-dark'], B['is-paddingless'], B['is-marginless'])}>
      <div className={B['hero-body']}>
        <div className={classnames(B.columns)}>
          <div className={classnames(B.column, B['is-full'])}>
            <div className={B.level}>
              <div className={B['level-left']}>
                <div className={B['level-item']}>
                  <figure className={classnames(B.image, B['is-128x128'])}>
                    {props.top_url
                      ? <a href={props.top_url}><img className={B['is-rounded']} alt={props.name} src={props.image} /></a>
                      : <img className={B['is-rounded']} alt={props.name} src={props.image} />
                    }
                  </figure>
                </div>
                <div className={B['has-text-centered']}>
                  <h1 className={classnames(B.title, B['is-size-4'])}>{props.name}</h1>
                  <h3 className={classnames(B.subtitle, B['is-size-5'])}>{props.title}</h3>
                  <h3 className={classnames(B.subtitle, B['is-size-6'])}>(Focus: {props.focus})</h3>
                </div>
              </div>
              <div className={B['level-right']}>
                <div className={B['level-item']}>
                  <ContactList items={props.contacts} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
