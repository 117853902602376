import React from "react";
import { Link } from "react-router-dom";
import mergeClassNames from "classnames";
import BulmaCSS from "../../css/bulma.module.sass";
import Styles from "../../css/styles.module.css";
import Section from "./Section";
import { getFixedUrl } from '../../utils';
import { PureTagList } from "./TagsList";

type ItemDetails = {
  descriptions?: string[];
  organization_info?: Record<string, string>;
  stakeholders?: Record<string, string>;
  service_size?: Record<string, string>;
  responsibilities?: string[];
  achievements?: Achievement[];
  tags?: Record<string, string[]>;
  tagTitle?: string;
};

type Achievement = {
  year: string;
  impacts: Impact[];
};

type Impact = {
  what: string;
  why: string;
  how: string;
  impact: string;
};

type ListItem = {
  isThisItemVisible?: boolean;
  authority: string;
  authorityWebSite?: string;
  authorityMeta?: string;
  rightSide?: React.ReactNode;
  title: string;
  detailed_descriptions?: string[];
  descriptionTags?: string[];
  workLocation?: string;
  details?: ItemDetails;
};

type CommonListProps = {
  items: ListItem[];
  title?: string;
  description?: string;
  icon?: any; // Replace with a more specific type if known
  xtraClassName?: string;
};

export function DetailedCommonList(props: CommonListProps) {
  const { title, description, icon, items, xtraClassName } = props;

  return (
    <Section
      xtraClassName={xtraClassName}
      title={title}
      content={description}
      icon={icon}
    >
      <div
        className={mergeClassNames(
          BulmaCSS.container,
          BulmaCSS["is-medium"],
          Styles.commonListContainer
        )}
      >
        {items.map((item, index) => {
          const {  isThisItemVisible, authority, authorityWebSite, authorityMeta, rightSide, title, detailed_descriptions, descriptionTags, workLocation, details} = item;
          return (
            isThisItemVisible &&
            <div className={mergeClassNames(BulmaCSS.content, Styles.avoidBreakingOnPrint)}>
                {/* Occupation and dates*/}
                <div className={mergeClassNames(BulmaCSS.level, BulmaCSS['is-marginless'], BulmaCSS['is-paddingless'])}>
                  <h5 className={mergeClassNames(BulmaCSS.title, BulmaCSS['is-marginless'], BulmaCSS['level-left'], BulmaCSS['is-size-5'])}>{title}
                  </h5>
                  <span className={mergeClassNames(BulmaCSS['level-right'])}>{rightSide}</span>
                </div>
                {/* Work location */}
                <h6
                  className={mergeClassNames(BulmaCSS.subtitle, Styles.companyTitle, BulmaCSS['is-size-6'])}
                >
                  {authorityWebSite ? <a href={getFixedUrl(authorityWebSite)} target='_blank' rel="noreferrer" >{authority}</a> : authority} {workLocation ? `/ ${workLocation}` : null}
                </h6>
                {/*  Authority Meta */}
                { authorityMeta
                  ? <span className={mergeClassNames(Styles.companyMeta)}>{`(${authorityMeta})`}</span>
                  : null}
                {/* descriptions */}
                { details?.descriptions &&
                <>
                  <p/>
                  <h6><u>Description</u></h6>
                  {details.descriptions.map((description, index) => (
                    <p key={index} className={Styles.description}>
                      {description}
                    </p>
                  ))}
                </>
                }
                {/*  Organization information */}
                { details && renderOrganizationInfo(details)}
                {/*  Organization stakeholders */}
                { details && renderOrganizationStakeholder(details)}
                {/*  Organization service size */}
                { details && renderServiceSize(details)}
                {/*  Organization routine duties */}
                { details && renderRoutineDuty(details)}
                {/*  Achivements */}
                {item.details?.achievements &&
                  renderAchievements(item.details.achievements)}
                {/*  Tag */}
                {details && renderTags(details)}
            </div>
          );
        })}
        <Link to="/">Back to top page</Link>
      </div>
    </Section>
  );
}
function renderOrganizationInfo(details: ItemDetails) {
  if (!details.organization_info) {
    return null;
  }

  return (
    <>
      <h6><u>Organization Information</u></h6>
      <ul className={Styles.description}>
        {Object.entries(details.organization_info).map(([key, value]) => (
          <li key={key}>{key}: {value}</li>
        ))}
      </ul>
    </>
  );
}

function renderOrganizationStakeholder(details: ItemDetails) {
  if (!details.stakeholders) {
    return null;
  }

  return (
    <>
      <h6><u>Organizational Stakeholders</u></h6>
      <ul className={Styles.description}>
        {Object.entries(details.stakeholders).map(([key, value]) => (
          <li key={key}>{key}: {value}</li>
        ))}
      </ul>
    </>
  );
}

function renderServiceSize(details: ItemDetails) {
  if (!details.service_size) {
    return null;
  }

  return (
    <>
      <h6><u>Service Size</u></h6>
      <ul className={Styles.description}>
        {Object.entries(details.service_size).map(([key, value]) => (
          <li key={key}>{key}: {value}</li>
        ))}
      </ul>
    </>
  );
}

function renderRoutineDuty(details: ItemDetails) {
  if (!details.responsibilities) {
    return null;
  }

  return (
    <>
      <h6><u>Routine duties beyond the project</u></h6>
      <ul className={Styles.description}>
        {Object.entries(details.responsibilities).map(([key, value]) => (
          <li key={key}>{value}</li>
        ))}
      </ul>
    </>
  );
}

function renderTags(details: ItemDetails) {
  if (!details.tags) {
    return null;
  }

  return (
    <>
                      <p>
                  <h6><u>{details?.tagTitle ? details.tagTitle : 'Expertise'}</u></h6>
                    {Object.entries(details.tags).map(([key, value]) => (
                      <div key={key} className={Styles.description}>
                        <h6>{key}</h6>
                        <PureTagList tags={value} tagClass='is-dark' />
                      </div>
                    ))}
                  </p>
    </>
  );
}


function renderAchievements(achievements: Achievement[]) {
  return (
    <p>
      <h6>
        <u>Achievements</u>
      </h6>
      {achievements.map((achievement, index) => (
        <div key={index}>
          <p/> {/* better to arrange the line blanks between year and details with <p/>  */}
          <h6>{achievement.year}</h6>
          <div>
            {achievement.impacts.map((item, idx) => renderImpact(item, idx))}
          </div>
        </div>
      ))}
    </p>
  );
}

function renderImpact(item: Impact, idx: number) {
  return (
    <ul key={idx}>
      <li>
        <b>What</b>: {item.what}
        <br />
        <b>Why</b>: {item.why}
        <br />
        <b>How</b>: {item.how}
        <br />
        <b>Impact</b>: {item.impact}
      </li>
    </ul>
  );
}
