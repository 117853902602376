// Make this flag true when you want to create a resume and  omit low priority sections.

import { CVRootProps } from "./Components/Base/CVRoot";
import { COMMON_LIST_TITLE_EDUCATION, COMMON_LIST_TITLE_LANGUAGES, DATA_TYPE_COMMON_LIST, DATA_TYPE_EXPERIENCE_LIST, DATA_TYPE_PUBLIC_EXPOSURE, EXPERIENCE_LIST_TITLE_SIDE_JOB, TYPE_ABOUT_ME, TYPE_CALLOUT_GUIDE_FOR_MORE_INFO } from "./Components/TopPage";

// Because I want to keep the resume at max 2 pages, many sections must be excluded.
const isResumeByPDF = false;
export const CVData: CVRootProps = {
  personalData: {
    name: 'Takayuki Watanabe',
    title: 'Software Engineer',
    focus: 'Machine Learning, SaaS, SRE, Developer Productivity',
    image: 'https://www.gravatar.com/avatar/c431886a52f7545f1b122b0c221e7bcd',
    top_url: 'https://takanabe.tokyo',
    isResume: isResumeByPDF,
    contacts: [
      { type: 'email', value: 'takanabe.w[at]gmail.com' },
      { type: 'location', value: 'Tokyo, Japan' },
      { type: 'website', value: 'blog.takanabe.tokyo' },
      { type: 'linkedin', value: 'linkedin.com/in/takanabe' },
      { type: 'twitter', value: 'twitter.com/takanabe_w' },
      { type: 'github', value: 'github.com/takanabe' },
      { type: 'speakerdeck', value: 'speakerdeck.com/takanabe' },
    ]
  },
  sections: [
    {
      title: 'About me',
      isThisSectionVisible: !isResumeByPDF, // false for CV
      type: TYPE_ABOUT_ME,
      description: `Takayuki Watanabe, known as Taka, takanabe, or 渡辺喬之, is a seasoned software engineer working since 2010 as software developers. specializing in Site Reliability Engineering (SRE) and Developer Productivity. Over the past decade, he has dedicated himself to the development and delivery of highly reliable services on top of cloud platforms like AWS and GCP. He has a strong commitment to ensuring the reliability, automation, and scalability of these services. He had previously led a multicultural organization with members from over 30 nationalities, demonstrating his ability to manage and collaborate with diverse groups.

In recent years, he has broadened his skill set into the domain of machine learning, concentrating on the analysis of tabular data and textual content. He has adeptly utilized a variety of advanced machine learning techniques, particularly those based on Gradient Boosting Decision Tree/Transformer-based models, to create cutting-edge SaaS solutions. These solutions are designed to elevate developer productivity by harnessing the capabilities of machine learning. They offer developers an array of powerful tools and insights, significantly enhancing the efficiency and effectiveness of their workflows.

Leveraging expertise in web application, SRE, Developer Productivity, and Machine Learning, Taka drives the development of software products. His proficiency extends to adeptly selecting and adapting technologies to suit organizational needs, project requirements, stakeholder expectations, and constraints, with a keen ability to quickly upskill when required.`,
      icon: 'usertie'
    },
    {
      type: TYPE_CALLOUT_GUIDE_FOR_MORE_INFO,
      // title: 'callout-guide-for-more-info',
      isThisSectionVisible: isResumeByPDF, // true for CV
    },
    // {
    //   type: 'about-this-cv',
    //   title: 'Summary of this CV',
    //   isThisSectionVisible: !isResumeByPDF, // false for CV
    //   descriptions: [
    //     {
    //       title: 'Career',
    //       content: 'A software engineer with diverse experience since 2010,  thriving in startups, mid-sized companies, and large corporations by blending innovation with a wealth of experience.'
    //     },
    //     {
    //       title: 'Broad Skill Set',
    //       content: 'Effectively combines web application, SRE, Developer Productivity, and Machine Learning technology to develop customized software solutions, adeptly adapting to varied project requirements.'
    //     },
    //     {
    //       title: 'Leadership',
    //       content: 'Successfully led a diverse, multicultural team from over 30 nationalities, demonstrating strong collaborative and management capabilities.'
    //     },
    //     {
    //       title: 'Weekend Tech Enthusiast',
    //       content: 'Utilizes weekends for side jobs, keeping pace with the latest technology trends, and continuously evolving his skills in the fast-changing tech landscape.'
    //     },
    //   ],
    //   icon: 'usertie'
    // },
    {
      title: 'Professional experience',
      isThisSectionVisible: true,
      type: DATA_TYPE_EXPERIENCE_LIST,
      // description: 'Optional',
      icon: 'archive',
      detailedPagePath: "/professional-experience",
      visibleItemCount: 3,
      items: [
        {
          // title: 'Senior Software Engineer in Machine Learning',
          isThisItemVisible: true, // always show this section
          title: 'Senior Software Engineer in Machine Learning',
          company: 'Launchable, Inc.',
          workLocation: 'Full-remote',
          bulletWorkExplanations: [
            "Developed software test analysis system using Large Language Models (LLMs), which proficiently grouped test failures, achieving a remarkable maximum reduction of 95% in the number of test cases requiring manual review.",
            "Improved the ROC AUC (Receiver Operating Characteristic - Area Under Curve) of Predictive Test Selection service by 5% for the majority of our customers by integrating insights from both recent and past test failures, enhancing the model's predictive accuracy and overall performance.",
            "Developed machine learning-based SaaS products, encompassing both web application (frontend) and backend APIs.",
            "Implemented a scalable ML infrastructure using AWS, GCP, and Kubernetes, improving team productivity and focusing resources on application development.",
            "Built custom, scalable ML pipelines, reducing client testing times by 30% and operational costs by 20% through effective Jenkins and Kubernetes integration."
          ],
          description: "Excelled in crafting and enhancing Launchable's suite of machine learning-driven SaaS offerings, including flagship products like Predictive Test Selection and Auto Test Triage. My contributions have markedly elevated developer productivity for clients. Beyond product development, I am instrumental in architecting and sustaining the robust Launchable infrastructure/platform, guaranteeing streamlined and dependable product deployment.",
          companyWebSite: 'https://www.launchableinc.com/',
          datesBetween: 'January 2022 - Present',
          descriptionTags: ['Java', 'Spring Boot', 'DGS framework', 'TypeScript', 'Next.js', 'GraphQL', 'Apollo', 'Python', 'AWS', 'Amazon EKS', 'Amazon Aurora PostgreSQL', 'LLM', 'Vertex AI', 'BigQuery', 'Bazel', 'Auth0',],
          details: {
            descriptions: [
              "Excelled in crafting and enhancing Launchable's suite of machine learning-driven SaaS offerings, including flagship products like Predictive Test Selection and Auto Test Triage. My contributions have markedly elevated developer productivity for clients. Beyond product development, I am instrumental in architecting and sustaining the robust Launchable infrastructure/platform, guaranteeing streamlined and dependable product deployment.",
            ],
            organization_info: {
              "Funding round": "Series A",
              "Organization size": "+20 people",
              "Office location": "United States",
              "Common language": "English",
            },
            stakeholders: {
              "CEOs": "Collaborated closely with two CEOs to deliver critical startup projects.",
              "Product manager": "Engaged in discussions and product design.",
              "Customer success": "Collaborated on customer support initiatives.",
              "My team members": "Worked with 5 to 8 fullstack developers daily on project delivery and retrospectives."
            },
            responsibilities: [
              "Delivery of production infrastructure and service.",
              "Customer success management.",
              "Swiftly switched roles based on necessities to develop 0 -> 1 product.",
            ],
            achievements: [
              {
                "year": "2023",
                "impacts": [{
                  "what": "Implemented 'time since last failure' feature and short-term memory in Predictive Test Selection SaaS.",
                  "why": "To enhance test prediction accuracy by addressing ongoing failures missed during ML training.",
                  "how": "Integrated 'time since last failure' metric into the predictive model for recent failure tracking and developed a short-term memory system for auxiliary data storage, enriching the inference process.",
                  "impact": "Reduced missed tests and improved testing effectiveness, especially in Pull Requests, leading to operational hour and cost savings, and elevating software quality."
                },
                {
                  "what": "Developed LLM-based automatic test result analysis system.",
                  "why": "To optimize root cause analysis by efficiently grouping similar test failures, reducing QA and developer time.",
                  "how": "Utilized OpenAI and Google Cloud for LLM access, with Vertex AI Vector Search for data analysis and failure categorization.",
                  "impact": "Significantly improved client testing processes; one client reduced test failure checks from 100 to 5, enhancing issue resolution and software quality."
                },
                {
                  "what": "Implemented a system for identifying 'Unhealthy Tests' in customer test suites.",
                  "why": "To increase testing efficiency and reliability by targeting problematic tests for improvement.",
                  "how": "Used Spark and BigQuery for data analysis, identifying critical tests and visualizing them in a web app for easy assessment.",
                  "impact": "Enhanced customers' testing processes, enabling early detection and resolution of inefficient tests, improving software development and QA."
                },
                {
                  "what": "Researched machine learning-based clustering of test failures using NLP.",
                  "why": "As a proof of concept to classify test failure root causes using NLP for enhanced system delivery.",
                  "how": "Applied transformer-based embeddings, Levenshtein distance, and TF-IDF for in-depth textual analysis of test failure reports.",
                  "impact": "Provided valuable insights into test failure dynamics, informing future product features and enhancing system efficiency."
                },
                {
                  "what": "Conducted market research through user interviews for Launchable's new feature.",
                  "why": "To gather direct feedback from QAs and developers and assess the Product-Market Fit of the new feature.",
                  "how": "Interviewed about 20 professionals, analyzed feedback to identify common themes and insights for feature development.",
                  "impact": "Informed feature design and strategy, enhancing market relevance and likelihood of user adoption."
                },
                {
                  "what": "Implemented Frontend GraphQL Fragment Colocation.",
                  "why": "To address developer experience issues caused by inadequate GraphQL practices, improving application stability.",
                  "how": "Adopted GraphQL fragment colocation, colocating query fragments with React components to streamline data management.",
                  "impact": "Enhanced development process and application stability, reducing data-related incidents and improving reliability."
                },
                {
                  "what": "Delivered a robust and scalable production infrastructure.",
                  "why": "To establish a high-performance, reliable environment for product development and operation.",
                  "how": "Leveraged AWS and GCP for infrastructure, upgraded Kubernetes, and implemented comprehensive monitoring with Sentry and Datadog.",
                  "impact": "Reduced team workload, allowing developers to focus on application development and improving workflow efficiency."
                },
                {
                  "what": "Upgrade EKS versions",
                  "why": "Amazon EKS requires periodic API updates aligned with Kubernetes updates",
                  "how": "Created a new Terraform module focusing on EKS bootstrap, streamlining the process of upgrading to new EKS versions.",
                  "impact": "Enabled the launch of new EKS cluster versions with minor changes, significantly simplifying the update process and reducing deployment time."
                },
                {
                  "what": "Achieved SOC2 compliance for SaaS.",
                  "why": "To ensure security compliance, safeguarding customer data and building trust.",
                  "how": "Led SOC 2 compliance projects, coordinated with legal and external testers to ensure system security and compliance.",
                  "impact": "Enhanced security posture and boosted the Proof of Concept cycle, obtaining SOC 2 Type 1 and Type 2 certifications."
                }
                ]
              },
              {
                "year": "2022",
                "impacts": [
                  {
                    "what": "Delivered scalable, multi-tenant ML pipelines for client-specific needs.",
                    "why": "To provide clients with tailored ML models and efficient management of their ML pipelines.",
                    "how": "Used Jenkins for pipeline codification and integration, and Kubernetes for dynamic deployment, ensuring scalability and robustness.",
                    "impact": "Enabled customized ML solutions, reducing testing times by 30% and operational costs by 20% for clients."
                  },
                  {
                    "what": "Implemented 'Observation Mode' in predictive test selection.",
                    "why": "To enhance test process efficiency and boost client confidence in our predictive model.",
                    "how": "Analyzed inference results against actual outcomes, measuring key performance metrics and optimizing test selection.",
                    "impact": "Doubled the smoothness of the POC process and reduced testing time by 30% for clients, without compromising test quality."
                  },

                  {
                    "what": "Developed the Launchable Hands-on Lab app for exploration of Launchable features.",
                    "why": "To enhance user engagement and ease of access, offering an interactive platform for new users to familiarize themselves with Launchable's capabilities. Aimed to reduce the Proof of Concept (POC) period and accelerate user adoption.",
                    "how": "Integrated the app with GitHub for repository forking and direct feature interaction. Ensured an intuitive user interface with clear guidance and comprehensive documentation, making it accessible to users of varying technical expertise.",
                    "impact": "Despite its initial promise, the app was phased out in favor of the more effective 'Observation Mode'. The swift shift in focus demonstrates our flexibility and responsiveness to user needs and product efficiency, highlighting our commitment to adapting and optimizing resources in a dynamic start-up environment."
                  },
                  {
                    "what": "Developed a Slack app for efficient onboarding and test result notifications.",
                    "why": "To improve communication and provide direct access to test results, enhancing the onboarding experience.",
                    "how": "Integrated the app with Slack, featuring OAuth authentication and real-time notifications and updates on test results.",
                    "impact": "Streamlined test result checking and onboarding process, fostering a more cohesive and productive work environment."
                  },
                  {
                    "what": "Delivered a robust and scalable production infrastructure.",
                    "why": "To provide a reliable, high-performance environment for product development.",
                    "how": "Utilized AWS and GCP, enhanced observability with Sentry and Datadog, managing all infrastructure aspects.",
                    "impact": "Eased team workload, enabling focus on application development, thus increasing productivity and efficiency."
                  },
                  {
                    "what": "Achieved security compliance for customer confidence in SaaS.",
                    "why": "To safeguard customer data and enhance market competitiveness through compliance.",
                    "how": "Led SOC 2 compliance, vulnerability fixes, penetration testing projects, and introduced endpoint security.",
                    "impact": "Established and executed security policies, preparing Launchable for SOC 2 certification."
                  }
                ]
              },
            ],
            tagTitle: "Technology stack used in production",
            tags: {
              "Programming Language": ['Java', 'Python', 'TypeScript'],
              "Operating System": ['Amazon Linux 2'],
              "Cloud provider": ['AWS', 'GCP'],
              "Web Application Framework": ['Spring Boot', 'DGS framework', 'Next.js'],
              "API format & Interservice Communication Protocol": ['GraphQL', 'RESTFul API'],
              "Container Orchestrator": ['Amazon EKS'],
              "Serverless Compute": ['AWS Lambda'],
              "Virtual Machine": ['Amazon EC2'],
              "Infrastructure-as-Code": ['Terraform'],
              "Kubernetes manifests management": ['Kustomize', 'Helm'],
              // alphabetical order from here to 'Misc'
              "Build and test": ['Bazel'],
              "CDN": ['Amazon CloudFront'],
              "CI/CD": ['AWS CodeBuild', 'GitHub Actions', 'Jenkins'],
              "Data science": ['NumPy', 'Pandas', 'Jupyter Notebook (Google Colab)'],
              "Data warehouse": ['Google BigQuery', 'Amazon EMR'],
              "Datastore": ['Amazon Aurora PostgreSQL', 'Vertex AI Vector Search'],
              "Data Serialization": ['Protocol Buffers'],
              "Identity": ['Auth0'],
              "Load Balancer & Proxy": ['Amazon Elastic Load Balancing ALB', 'Amazon Elastic Load Balancing CLB', 'Amazon Elastic Load Balancing NLB'],
              "Machine Learning": ['XGBoost', 'LLM', 'Retrieval-Augmented Generation'],
              "Object Storage": ['Amazon S3'],
              "Observability": ['Amazon CloudWatch', 'Datadog', 'Sentry'],
              "Misc": ['Figma', 'Chromatic'],
            },
          },
        },
        {
          isThisItemVisible: isResumeByPDF, // only display when resume mode enabled,
          title: 'Senior Software Engineer / Lead for Platform (Infra / SRE / Dev productivity)',
          company: 'Cookpad Inc.',
          workLocation: 'UK, Bristol & Remote',
          // description: 'Transitioned from tech lead to Senior Site Reliability Engineer at Cookpad, focusing on individual contributions. Led the development of a scalable AWS container platform, improved service stability and performance, and streamlined troubleshooting processes. Implemented SRE solutions like SLOs and a postmortem culture, enhancing software delivery and operational efficiency. Played a key role in incident analysis, mitigation, and automation to ensure system reliability.',
          bulletWorkExplanations: [
            "Orchestrated a diverse team of over 150 professionals from 30 nationalities, effectively leading organizational strategies and operations within a six-member leadership group.",
            "Launched a robust infrastructure from the ground up and delivered for 80 countries and 100 million users, leveraging AWS and container technology for global scalability and reliability, which handled about 4000 requests per second and achieved 500 million page views per month",
            "Designed several microservices, such as a real-time news feed, recipe search engine and proof-of-concept features, and contributed to their implementation through collaborative efforts with developers and machine learning researchers.",
            "Developed a suite of in-house software tools crucial for enhancing software development activities, including advanced incident response systems, streamlined container application deployment, efficient online DB schema migration, and comprehensive observability tools for container applications.",
            "Managed the largest database, efficiently processing 10k read and 5k write queries per second, showcasing robust database handling and optimization skills."
          ],
          companyWebSite: 'https://www.cookpadteam.com',
          datesBetween: 'April 2017 - January 2022',
          descriptionTags: ['Go', 'Ruby', 'Ruby on Rails', 'AWS', 'Amazon Aurora MySQL', 'DynamoDB', 'Amazon EKS', 'Amazon ECS', "Terraform", "Grafana", "Prometheus", "Alertmanager", "Elasticsearch", "Fastly", "Kafka"],
        },
        {
          isThisItemVisible: !isResumeByPDF, // only display when resume mode disabled,
          title: 'Senior Software Engineer in Platform (Infra / SRE / Dev productivity)',
          company: 'Cookpad Inc.',
          isThisSectionVisible: true,
          workLocation: 'Full-remote',
          description: 'Transitioned from tech lead to Senior Site Reliability Engineer at Cookpad, focusing on individual contributions. Led the development of a scalable AWS container platform, improved service stability and performance, and streamlined troubleshooting processes. Implemented SRE solutions like SLOs and a postmortem culture, enhancing software delivery and operational efficiency. Played a key role in incident analysis, mitigation, and automation to ensure system reliability.',
          bulletWorkExplanations: [
            "Developed a suite of in-house software tools crucial for enhancing software development activities, including advanced incident response systems, efficient online DB schema migration, traffic mirroring, and comprehensive observability tools for container applications.",
            "Developed specialized Terraform modules tailored for Kubernetes, aimed at teams opting for Kubernetes as their primary development platform. These modules incorporated Cookpad's internal best practices, facilitating the efficient deployment of Kubernetes on Amazon EKS (Elastic Kubernetes Service).",
            "Designed microservices, such as  a recipe search engine and proof-of-concept features powered by machine learning, and contributed to their implementation through collaborative efforts with developers and machine learning researchers.",
            "Mentored fellow software engineers in deploying Kubernetes and AWS technologies, guiding them towards achieving their ideal service delivery model for a new search engine project.",
          ],
          companyWebSite: 'https://www.cookpadteam.com',
          datesBetween: 'April 2020 - January 2022',
          descriptionTags: ['Go', 'Ruby', 'Ruby on Rails', 'AWS', 'Amazon Aurora MySQL', 'DynamoDB', 'Amazon EKS', 'Amazon ECS'],
          details: {
            descriptions: [
              "Transitioned from a tech lead role to focus as an individual contributor, entrusting leadership to a capable team member. In my role as Senior Site Reliability Engineer at Cookpad, I was instrumental in building and managing a high-performing infrastructure platform. Key achievements included developing a scalable AWS-based container deployment platform, enhancing Cookpad's global service through improved stability and performance, and elevating observability for streamlined troubleshooting. I contributed significantly to product teams by implementing SRE solutions like Service Level Objectives, a robust postmortem culture, and an effective incident handling process. My focus on key metrics led to accelerated software delivery and enhanced operational efficiency. I actively engaged in incident analysis and mitigation, developing preventive solutions and automations to ensure system reliability.",
            ],
            organization_info: {
              "Organization size": "+150 people",
              "Office location": "Bristol, United Kingdom",
              "Common language": "English",
            },
            stakeholders: {
              "My team members": "8 people (every day) to deliver team projects and retrospectives.",
              "Rails application developers": "About 20 people (every day) to support product developments.",
              "Search platform teams": "About 20 people (several projects) to support product developments.",
              "ML infra teams": "About 5 people (several projects) to support ML infra/research integrations in production.",
              "Community managers": "Uncountable but so many people across the globe (demand basis).",
            },
            service_size: {
              "Monthly average users": "Around 100 million people.",
              "Expanded countries": "More than 70 countries across the world.",
              "User requests": "About 4000 requests per sec. 500 million page views per month.",
              "DB size": "The largest DB includes a table having hundreds of millions of rows(hundreds GB).",
              "DB workload": "The largest DB handles 10k read and 5k write queries per sec.",
            },
            responsibilities: [
              "Delivery of production infrastructure and service with 24/7 on-call supports.",
              "AWS cost reduction by several tens of percent by making full use of reserved instances, Savings Plans, and spot instances",
            ],
            achievements: [
              {
                "year": "2021",
                "impacts": [
                  {
                    "what": "Built a new AWS/Kubernetes based platform for the search team.",
                    "why": "The lead engineers in the search team desires full controls of their platform. Because involving all software life-cycles including platform development and delivery make the team scalable even if the number of developers increase increase. Also, the search team can choose suitable technologies for their search products. In addition, the search team can control the feature release timing and strategy with new platform. Therefore, enabling the search (non-SRE) teams to deliver their own platform is a crucial part for them.",
                    "how": "He's been seconded to the search team as an embedded SRE member since the start of January 2021. He's been collaborating closely with the search team members. He frequently has pair-programming and pair-operation for the team members and transfer his technical knowledge around AWS, Kubernetes, Terraform, observability and operation automation.",
                    "impact": "The search can deliver the new platform autonomously without SREs' support, which means that the team can control full cycles of software delivery (See https://sourcediving.com/search-at-cookpad-building-new-infrastructure-dc58f4eab93f for more details.).",
                  },
                  {
                    "what": "Provide abstraction tools for traffic mirroring.",
                    "why": "When developers implement new APIs, they want to test the APIs with the same amount of requests in production to recognize, bugs, performance degradations, availability before releases in production.",
                    "how": "Implement configuration generators for Traefik proxy and provide easy to follow interfaces for developers.",
                    "impact": "Reduce unexpected implementation behaviors and outages due to huge latency and API instability.",
                  },
                  {
                    "what": "Refactor a structure of mono-repository for applications managed by the SRE team.",
                    "why": "The SRE team has been converging all code from distributed repository to the mono-repository. However, application code were scattered in different places and directory in the mono-repository.",
                    "how": "Merge all distributed git repositories into one repository and setup mono-repository compatible CI/CD pipelines.",
                    "impact": "The SRE members can easily find all application code in a single directory.",
                  },
                  {
                    "what": "Simplify CI/CD pipelines for the SRE project to reduce maintenance cost",
                    "why": "Due to historical reasons, we've used Jenkins, Rundeck, CircleCI, CodeBuild to implement CI/CD pipelines. However, maintaining various software to achieve CI/CD pipelines increase cognitive load and maintainability less efficient..",
                    "how": "Gain deep knowledge(feature comparison) of major CI software we can use as of 2021 and migrate CI/CD pipelines to GitHub Actions.",
                    "impact": "Standardize CI systems in the SRE team and retire 25 % of CI systems and migrated the CI/CD pipelines to GitHub Actions.",
                  },
                  {
                    "what": "Understood technical comfort zones for the search team members and transfer technical knowledge to them.",
                    "why": "To prioritize which training and hands-on work to be done collaboratively with search engineers, which is vital so the team can gain knowledge and experiences how to deliver platform without SREs’helps.",
                    "impact": "More search team members have confidences about their technology stack to build new search infrastructure.",
                  },
                ],
              },
              {
                "year": "2020",
                "impacts": [
                  {
                    "what": "Handed over a tech lead role to another member",
                    "why": "He believes switching lead roles and independent contributor roles every few years grows him as a human and developer. In addition, writing code and systems are just fun for him.",
                    "impact": "The team started scaling with a talented tech lead and I can work on more hands-on tasks.",
                  },
                  {
                    "what": "Developed an incident management tool to provide stream-aligned incident handling process for organization.",
                    "why": "Well organized incident management tools can automate many steps for incident handling process and support developers to complete organization-wide processes.",
                    "impact": "Developers can start and share the status of incidents with the rest of teams easily and reduce Mean Time To Recover(MTTR).",
                  },
                  {
                    "what": "Developed asynchronous schema migration tools for MySQL and self-serviced the tool for Rails applications.",
                    "why": "Applying schema migration queries such as 'ALTER TABLE' to huge tables frequently causes resource saturations and short downtimes. To prevent such issues from happening, the SRE handled those difficult table schema changes instead of developers. However, this process had increased development lead time and communication cost across teams.",
                    "impact": "Reduced development lead time and improved reliability of our services.",
                  },
                  {
                    "what": "Setup logging infrastructure for Kubernetes platform and define structured logging interfaces.",
                    "why": "We didn't have logging platform for Kubernetes.",
                    "impact": "Developers can inject application logs to centralized logging platform.",
                  },
                  {
                    "what": "Implement and deliver unhealthy EC2 instance terminator",
                    "why": "We manually eliminate unhealthy instances from Auto Scaling Groups. If we can automate the operation, we can use our time for other stuff.",
                    "impact": "Operation time is reduced thanks since the SRE team does not have to take care of unhealthy EC2 instances due to AWS side issues.",
                  },
                  // {
                  //   "what": "Appliaction log reductions for log search performance and server costs (applications logs was reduced by 70 %)",
                  //   "why": "to be added",
                  //   "impact": "to be added",
                  // },
                  // {
                  //   "what": "Improvement of search performances for Ramadan + COVID19 traffics + Googlebot DDoS",
                  //   "why": "to be added",
                  //   "impact": "to be added",
                  // },
                  // {
                  //   "what": "Budget/cost control in the SRE team to build systems on AWS",
                  //   "why": "to be added",
                  //   "impact": "to be added",
                  // },
                ],
              },
            ],
            tagTitle: "Technology stack used in production",
            tags: {
              "Programming Language": ['Go', 'Ruby'],
              "Operating System": ['Amazon Linux 2', 'Ubuntu'],
              "Cloud provider": ['AWS'],
              "Web Application Framework": ['Ruby on Rails', 'Sinatra'],
              "API format & Interservice Communication Protocol": ['RESTFul API'],
              "Container Orchestrator": ['Amazon ECS', 'Amazon EKS'],
              "Serverless Compute": ['AWS Lambda'],
              "Virtual Machine": ['Amazon EC2'],
              "Infrastructure-as-Code": ['Codenize Tools', 'Terraform', 'Jsonnet', 'Itamae', 'AWS SAM'],
              "Kubernetes manifests management": ['Kustomize', 'Helm'],
              // alphabetical order from here to 'Misc'
              "CDN": ['Fastly', 'Amazon CloudFront'],
              "CI/CD": ['AWS CodeBuild', 'GitHub Actions', 'Jenkins'],
              "GitOps": ['Flux'],
              "Datastore": ['Amazon Aurora MySQL', 'ElastiCache Redis', 'ElastiCache Memcached', 'Amazon DynamoDB'],
              "Data Serialization": ['Apache Avro'],
              "Event Streaming and Event Storage": ['Apache Kafka on Confluent Cloud', 'Apache Kafka on Strimzi'],
              "Load Balancer & Proxy": ['Amazon Elastic Load Balancing ALB', 'Amazon Elastic Load Balancing CLB', 'Amazon Elastic Load Balancing NLB', 'Envoy', 'HAProxy', 'NGINX', 'Traefik'],
              "Object Storage": ['Amazon S3'],
              "Observability": ['Grafana', 'Prometheus', 'Thanos', 'Alertmanager', 'Amazon CloudWatch', 'Sentry', 'New Relic', 'Elasticsearch', 'Kibana', 'Fluentd', 'Filebeat'],
              "Search Engine": ['Elasticsearch', 'Amazon Elasticsearch Service'],
              "Misc": ['Percona Toolkit'],
            },
          },
        },
        {
          isThisItemVisible: !isResumeByPDF, // only display when resume mode disabled,
          title: 'Chapter Lead/Senior Software Engineer in Platform (Infra / SRE / Dev productivity)',
          company: 'Cookpad Inc.',
          workLocation: 'UK, Bristol & Remote',
          isThisSectionVisible: !isResumeByPDF,
          description: "As Chapter Lead at Cookpad, I was responsible for availability, reliability, scalability, and observability of all products, aligning with global strategies and developing software to support team members in their development efforts. I also led the creation of a new Site Reliability Engineering team for international projects. In my concurrent role as Senior Site Reliability Engineer, I effectively managed and enhanced our infrastructure platform, specializing in incident analysis and root cause mitigation in adherence to our blameless postmortem culture. My contributions were critical in implementing automated solutions to bolster system reliability and prevent future issues.",
          bulletWorkExplanations: [
            "Orchestrated a diverse team of over 150 professionals from 30 nationalities, effectively leading organizational strategies and operations within a six-member leadership group.",
            "Launched a robust infrastructure from the ground up and delivered for 80 countries and 100 million users, leveraging AWS and container technology for global scalability and reliability, which handled about 4000 requests per second and achieved 500 million page views per month",
            "Developed a suite of in-house software tools crucial for enhancing software development activities, including advanced container application deployment and comprehensive observability tools for container applications.",
            "Designed microservices, such as a real-time news feed and private chat , and contributed to their implementation through collaborative efforts with developers.",
            "Managed the largest database, efficiently processing 10k read and 5k write queries per second, showcasing robust database handling and optimization skills."
          ],
          companyWebSite: 'https://www.cookpadteam.com',
          datesBetween: 'March 2017 - March 2020',
          descriptionTags: ['Go', 'Ruby', 'Ruby on Rails', 'AWS', 'Amazon Aurora MySQL', 'DynamoDB', 'Amazon EKS', 'Amazon ECS'],
          details: {
            descriptions: [
              "As a Chapter Lead, I spearheaded the establishment of a new Site Reliability Engineering team for Cookpad's international projects and was responsible for availability, reliability, scalability, and observability of all products. My role involved strategic planning, team building, and aligning our objectives with the company's global vision, while also developing software to support other team members in their development endeavors.",
              "In my concurrent role as a Senior Site Reliability Engineer at Cookpad, I was entrusted with the construction and operation of our infrastructure platform. This involved a range of critical activities: (1) Building a highly available, performant, and scalable container deployment platform using AWS. (2) Designing, developing, and implementing solutions to enhance the stability, scalability, availability, and performance of Cookpad’s Global service. (3) Improving observability of our platform and applications to streamline the troubleshooting process. (4) Assisting product teams in achieving availability goals by introducing Site Reliability Engineering solutions such as Service Level Objectives (SLOs), a blameless postmortem culture, and an efficient incident handling process. (5) Accelerating the software delivery cycle through the analysis of key numerical metrics like deployment frequencies, mean time to repair, development lead time, and change fail percentage. (6) Taking part in operational responsibilities and actively engaging in incident analysis and root cause mitigation as part of our blameless postmortem culture. My efforts were pivotal in building automated solutions to prevent the recurrence of issues, thereby enhancing system reliability."
            ],
            organization_info: {
              "Organization size": "+100 people (growth from 0 people)",
              "Office location": "Bristol, United Kingdom (relocation from US office)",
              "Common language": "English",
            },
            "stakeholders": {
              "Organization board members": "Engage bi-weekly with the CTO, VPoE for team building and strategic goal alignment.",
              "Chapter leads": "Collaborate with 5 chapter leads across the organization to co-design and implement a cohesive global engineering strategy.",
              "My team members": "Lead a team of 5, interacting daily to drive project delivery and conduct retrospectives.",
              "Rails application developers": "Support approximately 20 Rails developers daily, aiding in product development initiatives.",
              "Search platform teams": "Work with around 10 members on various projects, providing support for product development efforts.",
              "ML infra and research teams": "Coordinate with about 10 personnel on multiple projects, focusing on ML infrastructure and research integration into production.",
              "Community managers": "Regularly interact with a vast network of global community managers, as required, to support diverse community needs."
            },
            service_size: {
              "Monthly average users": "Around 80 million people.",
              "Expanded countries": "More than 60 countries across the world.",
              "User requests": "About 3000 requests per sec. 300 million page views per month.",
              "DB size": "The largest DB includes a table having hundreds of millions of rows(hundreds GB).",
            },
            responsibilities: [
              "SRE Chapter Lead.",
              "Decision making for next team actions based on numerical analysis of team capacity, team performance, and company business priorities.",
              "Recruitment of Site Reliability Engineers for Cookpad international projects.",
              "Onboarding and coaching for new and existing team members.",
              "Delivery of production infrastructure and service with 24/7 on-call supports.",
              "AWS cost reduction by several tens of percent by making full use of reserved instances, Savings Plans, and spot instances",
            ],
            // achievements: [
            //   {
            //     "year": "2020",
            //     "impacts": [
            //       {
            //         "what": "Took over all operational responsibilities from Cookpad Japan.",
            //         "why": "",
            //         "impact": "",
            //       },
            //       {
            //         "what": "Led all onboarding and offboarding for the team and standardized the processes.",
            //         "why": "To be added",
            //         "impact": "To be added",
            //       },
            //     ],
            //   },
            //   {
            //     "year": "2019",
            //     "impacts": [
            //       {
            //         "what": "To be added",
            //         "why": "To be added",
            //         "impact": "To be added",
            //       },
            //       {
            //         "what": "Deliver production infrastructure and service with 24/7 on-call supports",
            //         "why": "Our team was responsible for the last resort of production services with on-call rotations.",
            //         "impact": "End users could use Cookpad under our availability and security expectations",
            //       },
            //     ],
            //   },
            //   {
            //     "year": "2018",
            //     "impacts": [
            //       {
            //         "what": "To be added",
            //         "why": "To be added",
            //         "impact": "To be added",
            //       },
            //     ],
            //   },
            //   {
            //     "year": "2017",
            //     "impacts": [
            //       {
            //         "what": "To be added",
            //         "why": "To be added",
            //         "impact": "To be added",
            //       },
            //     ],
            //   },
            // ],
            tagTitle: "Technology stack used in production",
            tags: {
              "Programming Language": ['Go', 'Ruby', 'JavaScript (React)'],
              "Operating System": ['Ubuntu'],
              "Cloud provider": ['AWS'],
              "Web Application Framework": ['Ruby on Rails', 'Sinatra'],
              "API format & Interservice Communication Protocol": ['RESTFul API'],
              "Container Orchestrator": ['Amazon ECS'],
              "Serverless Compute": ['AWS Lambda'],
              "Virtual Machine": ['Amazon EC2'],
              "Infrastructure-as-Code": ['Codenize Tools', 'Terraform', 'Jsonnet', 'Itamae', 'AWS SAM'],
              // alphabetical order from here to 'Misc'
              "CDN": ['Fastly', 'Amazon CloudFront'],
              "CI/CD": ['AWS CodeBuild', 'GitHub Actions', 'Jenkins'],
              "Datastore": ['Amazon Aurora MySQL', 'ElastiCache Redis', 'ElastiCache Memcached', 'Amazon DynamoDB'],
              "Data Serialization": ['Apache Avro'],
              "Event Streaming and Event Storage": ['Apache Kafka on Confluent Cloud'],
              "Load Balancer & Proxy": ['Amazon Elastic Load Balancing ALB', 'Amazon Elastic Load Balancing CLB', 'Amazon Elastic Load Balancing NLB', 'Envoy', 'HAProxy', 'NGINX', 'Traefik'],
              "Object Storage": ['Amazon S3'],
              "Observability": ['Grafana', 'Prometheus', 'Thanos', 'Alertmanager', 'Zabbix', 'Amazon CloudWatch', 'Sentry', 'New Relic', 'Elasticsearch', 'Kibana', 'Fluentd'],
              "Search Engine": ['Elasticsearch', 'Amazon Elasticsearch Service', 'Solr'],
              "Misc": ['Percona Toolkit'],
            },
          },
        },
          {
          isThisItemVisible: !isResumeByPDF, // only display when resume mode disabled,
            title: 'Software Engineer in Platform (Infra / SRE / Dev productivity)',
            company: 'Cookpad Inc.',
            workLocation: 'Tokyo, Japan',
            description: 'As a Site Reliability Engineer, he was responsible for building and operating infrastructure of various services such as advertisement services, Cookpad baby, Cookpad cooking school, and Cookpad International.',
            companyWebSite: 'https://info.cookpad.com/',
            datesBetween: 'March 2016 - Feb 2017',
            descriptionTags: ['Ruby', 'PHP', 'Ruby on Rails', 'FuelPHP', 'AWS', 'MySQL', 'Amazon ECS', 'Site Reliability Engineering'],
            details: {
              descriptions: [
                "As a Site Reliability Engineer, he was responsible for building and operating infrastructure of various services such as advertisement services, Cookpad baby, Cookpad cooking school, and Cookpad International.",
              ],
              organization_info: {
                "Organization size": "+350 people",
                "Office location": "Tokyo, Japan",
                "Common language": "Japanese",
              },
              stakeholders: {
                "My team members": "8 people (a day for each week)",
              },
              responsibilities: [
                "Delivery of production infrastructure and service with 24/7 on-call supports.",
              ],
              achievements: [
                {
                  "year": "2016 & 2017",
                  "impacts": [
                    {
                      "what": "Implemented a bot-based deployment system for Rails applications.",
                      "why": "To provide a unified deployment interface for global developers without requiring server access.",
                      "how": "Developed a solution integrating Slack with webhooks and Rundeck, allowing developers to deploy Rails applications directly through Slack commands.",
                      "impact": "Simplified the deployment process, making it transparent and user-friendly. This allowed all team members to execute pre-defined deployment workflows with ease, enhancing operational efficiency."
                    },
                    {
                      "what": "Implemented autoscaling mechanisms and upgraded AWS resources for Cookpad during Ramadan.",
                      "why": "To handle the increased traffic during Ramadan, which historically led to outages due to inadequate compute and disk resources.",
                      "how": "Developed an in-house autoscaling system using HAProxy and NGINX configurations, and replaced underperforming resources with more efficient alternatives.",
                      "impact": "Ensured Cookpad remained operational during its busiest season, with no outages due to resource limitations."
                    },
                    {
                      "what": "Transferred various business units from the parent company to subsidiaries.",
                      "why": "As part of a strategic shift to focus on cooking-related businesses, necessitating migration of Rails applications to new AWS accounts and infrastructure setup.",
                      "how": "Automated deployment to AWS ECS, used Infrastructure as Code with Itamae, and set up monitoring and alerting software.",
                      "impact": "Completed all migrations within the deadline without downtime, reducing operational costs for the new entities."
                    },
                    {
                      "what": "Developed tools for deploying Amazon Aurora MySQL in high workload production environments.",
                      "why": "To address the lack of sufficient tooling for Amazon Aurora MySQL in demanding production settings.",
                      "how": "Created Fluentd plugins for analyzing Aurora logs using Percona Toolkit, including slow logs, to enhance data observability and analysis.",
                      "impact": "Enabled developers to efficiently analyze Aurora MySQL using a web UI (Kibana/Elasticsearch), improving data analysis and accessibility."
                    },
                    {
                      "what": "Upgraded MySQL servers for advertisement services.",
                      "why": "To address operational inefficiencies with outdated MySQL servers, which were using an older version and engine, and lacked  UTF8MB4 support for emoji.",
                      "how": "Migrated databases to InnoDB, replicating from the master using binary log, and seamlessly promoted the new DB using MHA.",
                      "impact": "Improved the stability and reliability of advertisement services with upgraded MySQL, now supporting emoji."
                    }
                  ]
                }
              ],
              tagTitle: "Technology stack used in production",
              tags: {
                "Programming Language": ['Ruby', 'PHP'],
                "Operating System": ['Ubuntu'],
                "Cloud provider": ['AWS'],
                "Web Application Framework": ['Ruby on Rails', 'Sinatra', 'FuelPHP'],
                "API format & Interservice Communication Protocol": ['RESTFul API'],
                "Virtual Machine": ['Amazon EC2'],
                "Infrastructure-as-Code": ['Codenize Tools', 'Itamae'],
                // alphabetical order from here to 'Misc'
                "CDN": ['Akamai', 'Amazon CloudFront', 'Fastly'],
                "CI/CD": ['Jenkins'],
                "Deployment": ['Capistrano'],
                "Datastore": ['MySQL', 'ElastiCache Redis', 'ElastiCache Memcached'],
                "Load Balancer & Proxy": ['Amazon Elastic Load Balancing CLB', 'Apache HTTP Server', 'HAProxy', 'NGINX'],
                "Service discovery": ["HashiCorp Consul"],
                "Object Storage": ['Amazon S3'],
                "Observability": ['Grafana', 'Zabbix', 'Amazon CloudWatch', 'Sentry', 'New Relic', 'Elasticsearch', 'Kibana', 'Fluentd'],
                "Search Engine": ['Elasticsearch', 'Solr'],
                "Misc": ['Percona Toolkit', 'MHA for MySQL', 'Consul Template'],
              },
            },
          },
          {
          isThisItemVisible: !isResumeByPDF, // only display when resume mode disabled,
            title: 'Junior Software & System Engineer in Cloud Platform',
            company: 'NTT Communications',
            workLocation: 'Tokyo, Japan',
            description: 'Industry-leading ISP that provides international consultancy, architecture, security and cloud services. He\'s worked in Technology Development Department, Cloud Platform Technology Unit and developed private cloud services as a company-wide IaaS.',
            companyWebSite: 'https://www.ntt.com/en/index.html',
            datesBetween: 'April 2013 – February 2016',
            descriptionTags: ['Python', 'Puppet', 'OpenStack', 'VMware vCenter', 'VMware Cloud Director', 'Juniper JUNOS', 'Cisco IOS', 'OSPF', 'BGP', 'On-Premise Network'],
            details: {
              descriptions: [
                "An industry-leading Internet Service Provider known for its international consultancy, architectural solutions, security, and cloud services. As a junior member in the Technology Development Department, specifically within the Cloud Platform Technology Unit, he played a collaborative role in the development of private cloud services as a comprehensive Infrastructure as a Service (IaaS) solution for company-wide use. Working alongside senior members, he contributed to integrating advanced cloud technologies and methodologies, aiding in the creation of a robust and scalable IaaS platform. This involvement provided valuable experience in cloud computing and digital transformation strategies within a highly professional setting."
              ],
              organization_info: {
                "Organization size": "+300,000 people",
                "Office location": "Tokyo, Japan",
                "Common language": "Japanese",
              },
              tagTitle: "Technology stack used in production",
              tags: {
                "Programming Language": ['Python', 'Ruby', 'JavaScript (React)'],
                "Operating System": ['Ubuntu', 'Windows Server'],
                "Web Application Framework": ['Flask', 'Ruby on Rails'],
                "API format & Interservice Communication Protocol": ['RESTFul API'],
                "Virtualization": ['OpenStack', 'Xen', 'VMWare ESXi', 'VMWare vCloud Director', 'VMWare vCenter'],
                "Infrastructure-as-Code": ['Puppet', 'Chef'],
                // alphabetical order from here to 'Misc'
                "CI/CD": ['Jenkins'],
                "Datastore": ['MySQL'],
                "Load Balancer & Proxy": ['Apache HTTP Server', 'NGINX'],
                "Network and Server devices": ['Dell PowerEdge', 'HP ProLiant', 'EMC SSD storage', 'Cisco', 'Juniper', 'SFP+', 'QSFP+'],
                "Network tech": ['On-Premise Network', 'BGP', 'GRE', 'OSPF', 'VLAN', 'VXLAN'],
                "Observability": ['Grafana', 'Zabbix', 'Elasticsearch', 'Kibana', 'Fluentd'],
              },
              achievements: [
                {
                  "year": "2015 & 2016",
                  "impacts": [
                    {
                      "what": "Developed and implemented VMWare and OpenStack-based IaaS platforms across multiple on-premise data centers.",
                      "why": "To establish a versatile, self-managed private cloud infrastructure for development and to assess OpenStack's potential for future private cloud services.",
                      "how": "Collaborated with cross-functional teams in integrating VMWare and OpenStack with existing data center resources, focusing on scalability and reliability to meet development needs.",
                      "impact": "Enabled company-wide access to IaaS platforms, enhancing development efficiency and flexibility, and laying the groundwork for future private cloud services."
                    }
                  ]
                },
                {
                  "year": "2013 & 2014",
                  "impacts": [
                    {
                      "what": "Conducted a comprehensive evaluation of VMWare and OpenStack-based IaaS platforms.",
                      "why": "To create a self-managed private cloud in on-premise data centers for development testing and to explore OpenStack for end-user cloud services.",
                      "how": "Executed pilot projects with VMWare and OpenStack, assessing their performance, scalability, and reliability, along with deployment ease and system compatibility.",
                      "impact": "Provided insights into the platforms' capabilities, influencing strategic decisions for the company's infrastructure and future cloud services."
                    },
                    {
                      "what": "Managed Network and Security Operations Centers, overseeing a vast internal network across several data centers.",
                      "why": "To deliver a robust infrastructure for company-wide product development and research, providing accessible test beds for all employees.",
                      "how": "Oversaw network and security systems integration and maintenance, ensuring performance and availability, and streamlining monitoring and incident response processes.",
                      "impact": "Optimized development and research processes, enabling efficient testing of new technologies and products, thus fostering quicker innovation and reduced time-to-market."
                    }
                  ]
                }

              ]
            },
          },
          {
          isThisItemVisible: !isResumeByPDF, // only display when resume mode disabled,
            title: 'Research developer in Cybersecurity',
            company: 'NTT Communications',
            workLocation: 'Tokyo, Japan',
            description: 'Industry-leading ISP that provides international consultancy, architecture, security and cloud services. He\'s worked in Technology Development Department, Cybersecurity Technology Unit and developed new systems for cybersecurity evaluation.',
            companyWebSite: 'https://www.ntt.com/en/index.html',
            datesBetween: 'April 2013 – February 2016',
            descriptionTags: ['Python', 'Flask', 'Puppet', 'Chef', 'OpenStack', 'FireEye', 'CrowdStrike'],
            details: {
              descriptions: [
                "An industry-leading Internet Service Provider renowned for its international consultancy, architecture, security, and cloud services. Within this organization, he worked in the Technology Development Department, specifically in the Cybersecurity Technology Unit. His role focused on developing innovative systems for cybersecurity evaluation, contributing to the advancement of security measures and protocols. This work involved leveraging cutting-edge technologies and methodologies to design and implement solutions that effectively assess and enhance cybersecurity defenses."
              ],
              organization_info: {
                "Organization size": "+300,000 people",
                "Office location": "Tokyo, Japan",
                "Common language": "Japanese",
              },
              achievements: [
                {
                  "year": "2016",
                  "impacts": [
                    {
                      "what": "Developed malware libraries and selection methods.",
                      "why": "To improve the efficiency of selecting suitable malware for security technology testing.",
                      "how": "Combined programming skills and cybersecurity analysis to categorize malware. Integrated dynamic analysis tools for accurate classification and created a user-friendly interface for malware retrieval.",
                      "impact": "Streamlined the malware selection process for security testing, offering quick access to relevant samples and enhancing the safety and effectiveness of tests."
                    }
                  ]
                },
                {
                  "year": "2015",
                  "impacts": [
                    {
                      "what": "Engineered virtual company environments and cyberattack simulation systems for security experiments.",
                      "why": "To realistically emulate company servers and networks for security testing, while maintaining safe isolation for potential malware infection.",
                      "how": "Created virtual environments mimicking real-world infrastructures using virtualization techniques, ensuring isolation and vulnerability to controlled cyberattacks.",
                      "impact": "Launched an IaaS-based ecosystem on OpenStack for proof-of-concept security testing, enabling effective experimentation and evaluation of security solutions."
                    },
                    {
                      "what": "Developed an activity log generator for Windows user behavior simulation.",
                      "why": "As part of a national project to study Advanced Persistent Threat (APT) attacks.",
                      "how": "Applied programming expertise and cybersecurity knowledge to simulate realistic user behavior, differentiating between normal and malicious activities.",
                      "impact": "Significantly contributed to an Attack Detection System for APTs, with the log generator playing a crucial role in enhancing threat identification and mitigation."
                    }
                  ]
                },
                {
                  "year": "2014",
                  "impacts": [
                    {
                      "what": "Developed a cyber security training program for NTT group employees.",
                      "why": "To meet the growing demand for comprehensive security training within the NTT groups.",
                      "how": "Worked with experts to design engaging content, interactive scenarios, and realistic environments, focusing on crucial security concepts.",
                      "impact": "Deployed the training program across NTT groups, elevating the security skills of the workforce and enhancing their awareness and practices."
                    },
                    {
                      "what": "Provisioned servers and networks for a scalable training environment using VMWare, and OpenStack.",
                      "why": "To build a robust training infrastructure for diverse cybersecurity training scenarios.",
                      "how": "Configured advanced network and server setups for high performance, employing virtualization and cloud management techniques.",
                      "impact": "Delivered an advanced training infrastructure, facilitating flexible and realistic cybersecurity training, improving learning experiences."
                    }
                  ]
                },
                {
                  "year": "2013",
                  "impacts": [
                    {
                      "what": "Completed training programs in Cyber Security, Web Application Development, Network Engineering, and Data Science.",
                      "why": "To equip new employees with current industry skills and knowledge.",
                      "how": "Participated in hands-on projects and workshops, applying modern software engineering tools and technologies.",
                      "impact": "Acquired practical engineering skills, enhancing proficiency in software development and problem-solving in real-world scenarios."
                    }
                  ]
                }
              ],
              tagTitle: "Technology stack used in production",
              tags: {
                "Programming Language": ['Python'],
                "Operating System": ['Ubuntu', 'Windows Server'],
                "Web Application Framework": ['Flask'],
                "API format & Interservice Communication Protocol": ['RESTFul API'],
                "Virtualization": ['OpenStack', 'VMWare ESXi', 'VMWare vCenter'],
                "Infrastructure-as-Code": ['Puppet', 'Chef'],
                // alphabetical order from here to 'Misc'
                "CI/CD": ['Jenkins'],
                "Datastore": ['MySQL'],
                "Load Balancer & Proxy": ['Apache HTTP Server', 'NGINX'],
                "Network and Server devices": ['Dell PowerEdge', 'HP ProLiant', 'Palo Alto FW', 'Cisco', 'Juniper', 'SFP+', 'QSFP+'],
                "Network tech": ['On-Premise Network', 'VLAN', 'Open vSwitch', 'GRE', 'OSPF'],
                "Observability": ['Grafana', 'Zabbix', 'Elasticsearch', 'Kibana', 'Fluentd'],
                "Search Engine": ['Elasticsearch'],
                "Security appliance": ['FireEye', 'CloudStrike', 'Palo Alto Firewall'],
                "Triage": ["RedLine"],
              },
            },
          },
      ]
    },
    {
      title: EXPERIENCE_LIST_TITLE_SIDE_JOB,
      isThisSectionVisible: isResumeByPDF,  // true for CV
      type: DATA_TYPE_EXPERIENCE_LIST,
      icon: 'archive',
      detailedPagePath: "/sidejobs",
      visibleItemCount: 4,
      items: [
        {
          isThisItemVisible: true, // always displayed
          title: 'Machine Learning Engineer',
          company: isResumeByPDF ? "Gaudiy Inc." : 'Company G in Metaverse Sector',
          companyWebSite: isResumeByPDF ? "https://gaudiy.com/" : null,
          workLocation: 'Full-remote',
          // description: 'Conducted in-depth research on the latest trends and advancements in Generative AI technology. Based on this research, I developed practical Proof of Concept (POC) projects and solutions. A notable example includes the implementation of a real-time voice changer using Retrieval-based Voice Conversion (RVC) technology.',
          bulletWorkExplanations: [
            "Designed an OSS-LLM serving platform for LLM agents to overcome OpenAI's limitations, focusing on Japanese language capabilities, with high-performance and resource efficiency.",
            "Developed a real-time voice changer using ML to emulate a celebrity's voice, enabling real-time voice conversion.",
            "Deployed an Image Generation AI Environment on GCP, simplifying AI image generation project development.",
            "Researched Multi-modal Foundation Models, providing insights and accurately predicting new model releases by OpenAI and Google.",
          ],
          datesBetween: 'Aug 2023 - Present',
          descriptionTags: ['Python', 'PyTorch', 'Pandas', 'Stable Diffusion', 'ComfyUI', 'RVC', 'HuBIRT', 'VITS', 'GCP', 'Vertex AI'],
          details: {
            descriptions: [
              'Conducted in-depth research on the latest trends and advancements in Generative AI technology. Based on this research, I developed practical Proof of Concept (POC) projects and solutions. A notable example includes the implementation of a real-time voice changer using Retrieval-based Voice Conversion (RVC) technology.'
            ],
            stakeholders: {
              "AI team lead": "The client's AI lead has requested that I conduct research on General AI technology and implement Proof of Concept projects.",
            },
            achievements: [
              {
                "year": "2023",
                "impacts": [
                  {
                    "what": "Designing Open Source Large Language Models (OSS-LLMs) serving platform for LLM agents",
                    "why": "To circumvent OpenAI's rate limits and high costs, and to explore Japanese language capabilities",
                    "how": "Utilized open-source models like Llama2 and ELYZA (a Japanese fine-tuned version of Llama2), combined with vLLM on GCP's Vertex AI",
                    "impact": "Ongoing evaluation, but poised to remove rate limits and create a resource-efficient, high performance LLM serving platform for LLM agents."
                  },
                  {
                    "what": "Develop real-time voice changer",
                    "why": "To utilize machine learning for generating a celebrity's voice, enhancing brand recognition and creating a unique user experience for the client.",
                    "how": "Implemented a retrieval-based voice conversion model trained on the celebrity's audio. Preprocessed audio for quality enhancement and used HuBERT for indexing during training, with FAISS for efficient embedding searches enabling real-time voice conversion.",
                    "impact": "Provided the capability to convert any voice into the celebrity's voice in real-time, significantly enhancing brand engagement."
                  },
                  {
                    "what": "Deploy Image Generation AI Environment",
                    "why": "To simplify the complexities involved in image generation AI development, such as managing Python, Docker, NVidia drivers, and CUDA dependencies.",
                    "how": "Created a user-friendly sandbox environment on Google Cloud Platform (GCP) with GPU instances, Vertex AI Workbench, Stable Diffusion, and various UI tools. Distributed the environment through Golden Images for easy setup and access.",
                    "impact": "Enabled seamless experimentation and development in image generation AI projects, boosting efficiency and innovation."
                  },
                  {
                    "what": "Research on Multi-modal Foundation Models",
                    "why": "To guide the client's future strategic planning in the face of uncertainty about multi-modal foundation models.",
                    "how": "Conducted an extensive review of academic research and SaaS solutions, focusing on advancements from OpenAI and Google Cloud Platform.",
                    "impact": "Provided crucial insights for the client's strategic planning. Accurately predicted the release of multi-modal foundation models by OpenAI and Google, which occurred a few months after the research, confirming our projections."
                  }

                ],
              },
            ],
            tagTitle: "Technology Stack",
            tags: {
              "Programming Language": ['Python'],
              "Machine learning & Data science": ['PyTorch', 'Pandas', 'NumPy', 'Vertex AI'],
              "Image Gen AI": ["Stable Diffusion", "ComfyUI"],
              "Audio Gen AI": ["Retrieval-based-Voice-Conversion", "VITS(Variational Inference with adversarial learning in Text-to-Speech)", "HuBERT"],
              "Operating System": ['Ubuntu'],
              "Cloud provider": ['GCP'],
              "Virtual Machine": ['GCE'],
              "Infrastructure-as-Code": ['Terraform'],
              "Object Storage": ['GCS'],
            },
          },
        },
        {
          isThisItemVisible: true, // always displayed
          title: 'Machine Learning Engineer',
          company: isResumeByPDF ? "Token Corporation" : 'Company T in Real Estate Sector',
          companyWebSite: isResumeByPDF ? "https://www.token.co.jp/" : null,
          workLocation: 'Full-remote',
          // description: "Developed question-and-answer system from scratch for internal documents, leveraging state-of-the-art Large Language Models (LLM). This pioneering project involved the integration of the 'LLM-as-Judge' framework with 'Retrieval-Augmented Generation' (RAG) technology, significantly boosting the system's precision and efficiency in information retrieval.",
          bulletWorkExplanations: [
            "Implemented a question and answering system using NLP, integrating with existing databases, significantly reducing information retrieval time and boosting employees' productivity.",
            "Evaluated inference performance of RAG and LLM, enhancing model efficiency and accuracy through iterative testing and improvements, leading to better data handling and user experience.",
            "Built development and production environments for a question and answering system on GCP enhancing development efficiency, security, and machine learning infrastructure."
          ],
          datesBetween: 'Nov 2022 - Aug 2023',
          descriptionTags: ['Python', 'PyTorch', 'Pandas', 'LangChain', 'GCP', 'Google Colab', 'Vertex AI Vector Search', 'RAG'],
          details: {
            descriptions: [
              "Developed question-and-answer system from scratch for internal documents, leveraging state-of-the-art Large Language Models (LLM). This pioneering project involved the integration of the 'LLM-as-Judge' framework with 'Retrieval-Augmented Generation' (RAG) technology, significantly boosting the system's precision and efficiency in information retrieval.",
            ],
            stakeholders: {
              "Corporate engineering head": "The client's corporate engineering head has requested that I develop QA system using machine learning",
            },
            achievements: [
              {
                "year": "2023",
                "impacts": [
                  {
                    "what": "Implement question and answering system",
                    "why": "To improve the efficiency of information retrieval within the organization, enabling employees to quickly access accurate answers to their queries.",
                    "how": "Developed a user-friendly system using advanced Natural Language Processing techniques, seamlessly integrating it with the company's existing knowledge base and databases.",
                    "impact": "Significantly reduced the time employees spend searching for information, boosting productivity, enhancing decision-making, and increasing satisfaction with internal resources."
                  },
                  {
                    "what": "Evaluate inference performance of RAG and LLM",
                    "why": "To enhance the efficiency and accuracy of our machine learning models for complex data processing tasks.",
                    "how": "Performed extensive testing and analysis using diverse datasets, refining the models through iterative improvements based on test results.",
                    "impact": "Achieved substantial improvements in the models' response times and accuracy, leading to more effective data handling and a better user experience."
                  },
                  {
                    "what": "Building development and production environment for QA system",
                    "why": "To support the company's adoption of software development and machine learning with the necessary infrastructure.",
                    "how": "Leveraged Google Workspace and Google Cloud Platform (GCP), integrating Identity Aware Proxy to restrict access to GCP services to specific Google groups.",
                    "impact": "Facilitated a more efficient development process, bolstering security, and providing a robust infrastructure for machine learning and software development. This has enabled the company to effectively utilize technology for internal processes, fostering enhanced productivity and innovation."
                  }

                ],
              },
            ],
            "tagTitle": "Technology Stack",
            tags: {
              "Programming Language": ['Python'],
              "Web framework": ['Streamlit'],
              "Machine learning & Data science": ['OpenAI', 'LangChain', 'PyTorch', 'Pandas', 'NumPy', 'Vertex AI Vector Search'],
              "Operating System": ['Ubuntu'],
              "Cloud provider": ['GCP'],
              "Container environment": ['Cloud Run'],
              "AuthN/AuthZ": ['Cloud Identify-aware Proxy'],
              "Infrastructure-as-Code": ['Terraform'],
              "Object Storage": ['GCS'],
            },
          },
        },
        {
          isThisItemVisible: true, // always displayed
          "title": "Frontend Developer",
          "company": isResumeByPDF ? "GOGEN Inc." : "Startup G in Real Estate Sector",
          bulletWorkExplanations: [
            "Developed web application for car parking systems, employing TypeScript, Next.js, and Tailwind CSS, and integrating QR-based expense management from scratch. This application led the creation of a new joint venture and successfully attracting venture capital funding.",
            "Provided expert consultation on AuthN/AuthZ design for backend services, utilizing Auth0/Cognito and in-house solutions, significantly enhancing security and user management efficiency."
          ],
          companyWebSite: isResumeByPDF ? "https://gogen.jp" : null,
          workLocation: 'Full-remote',
          "description": "Supported the development of an admin console for car parking management, starting from the ground up. This project culminated in a highly successful outcome. My work in creating this admin console played a pivotal role in the formation of a new joint venture company. This entity, backed by external funding, was established specifically to oversee and operate the car parking management system I developed.",
          "datesBetween": "May - Oct 2022",
          "descriptionTags": ["TypeScript", "Next.js", "React", "Tailwind CSS", "React Query"],
          "details": {
            "descriptions": [
              "Supported the development of an admin console for car parking management, starting from the ground up. This project culminated in a highly successful outcome. My work in creating this admin console played a pivotal role in the formation of a new joint venture company. This entity, backed by external funding, was established specifically to oversee and operate the car parking management system I developed."
            ],
            "stakeholders": {
              "CTO": "Collaborated with the client's CTO to design and implement the web application from scratch, including product functionality.",
              "Backend Developers": "Coordinated on API and AuthN/AuthZ design, and communicated to address gaps in API and data provision."
            },
            "achievements": [
              {
                "year": "2022",
                "impacts": [
                  {
                    "what": "Development of an admin console for car parking management",
                    "why": "To provide a more efficient and cost-effective solution for Japanese car parking systems, which traditionally rely on costly vending machines at entrances.",
                    "how": "Developed and deployed a web-based admin console utilizing technologies like TypeScript, Next.js, Tailwind CSS. The system features QR-based expense management for enhanced operational efficiency.",
                    "impact": "The successful launch of this system led to the formation of a new joint venture company, funded by an external partner, to manage and scale the solution, significantly modernizing car parking management in Japan."
                  },
                  {
                    "what": "Consultation on Authentication and Authorization (AuthN/AuthZ) design for backend services",
                    "why": "To enhance security and user management in backend services, addressing the need for robust and scalable authentication and authorization mechanisms.",
                    "how": "Provided expertise on Auth0/Cognito and in-house authentication and authorization implementations, sharing best practices and technical insights.",
                    "impact": "The consultation significantly improved the security posture and user access management of the client’s backend services, fostering a more secure and efficient operational environment."
                  }

                ]
              }
            ],
            "tagTitle": "Technology Stack",
            "tags": {
              "Programming Language": ["TypeScript"],
              "Web Framework": ["Next.js", "React", "Tailwind CSS", "Storybook", "React Query"],
              "Cloud provider": ["AWS", "Vercel"],
              "AuthN/AuthZ": ["Auth0"],
              "Misc": ['Figma'],
            }
          },
        },
        {
          isThisItemVisible: true, // always displayed
          "title": "Fullstack Developer",
          "company": isResumeByPDF ? "ACROVE, Inc." : "Startup A in the E-commerce Sector",
          companyWebSite: isResumeByPDF ? "https://acrove.co.jp/" : null,
          workLocation: 'Full-remote',
          // "description": "Developed comprehensive frontend and backend applications and infrastructure focused on analyzing sales data and proposing enhanced business strategies. This development played a crucial role in the startup securing funding from venture capital and advancing to its next funding series.",
          bulletWorkExplanations: [
            "Developed sales data analysis applications from scratch, utilizing Go and TypeScript on AWS, leading to a Series A venture capital funding milestone for startup growth.",
            "Designed a multi-tenant architecture with a token vending machine on AWS, enhancing security and scalability, and improving database performance in multi-tenant applications.",
            "Created a serverless API for accessing analyzed sales data, improving decision-making and user experience for business teams.",
            "Developed a cost-effective sales analyzer using AWS Lambda and DynamoDB, significantly reducing operational costs and improving data analysis scalability and reliability.",
          ],
          "datesBetween": "Aug 2020 - Jun 2021",
          "descriptionTags": ["Go", "TypeScript", "Next.js", "MaterialUI", "React Query", "AWS", "AWS Lambda", "DynamoDB", "MySQL"],
          "details": {
            "descriptions": [
              "Developed comprehensive frontend and backend applications and infrastructure focused on analyzing sales data and proposing enhanced business strategies. This development played a crucial role in the startup securing funding from venture capital and advancing to its next funding series."
            ],
            "stakeholders": {
              "CEO": "Worked closely with the CEO to design and implement the front-end, back-end, and infrastructure components of the applications."
            },
            "achievements": [
              {
                "year": "2021",
                "impacts": [
                  {
                    "what": "Development of Sales Data Analysis Applications",
                    "why": "To provide in-depth sales data analysis and strategic insights for business growth.",
                    "how": "Developed comprehensive front-end and back-end solutions using Go and TypeScript, integrating with AWS services such as Lambda, DynamoDB, and MySQL for enhanced scalability and reliability.",
                    "impact": "These applications attracted significant venture capital investment, marking a crucial milestone in the startup's growth and development."
                  },
                  {
                    "what": "Design multi-tenant architecture with token vending machine",
                    "why": "To bolster security and scalability in multi-tenant environments, managing resource access with dynamic IAM policies and effective data isolation.",
                    "how": "Implemented a cloud-based solution utilizing AWS, crafting a token vending machine for JWT authentication and tailoring IAM policies for precise access control. Emphasized secure token management and optimized DynamoDB partition key design for tenant data isolation.",
                    "impact": "Achieved a more secure, scalable system with enhanced access control, resulting in better database performance and efficient data segregation in multi-tenant applications."
                  }
                ]
              },
              {
                "year": "2020",
                "impacts": [
                  {
                    "what": "Develop serverless API for accessing analyzed sales data",
                    "why": "To provide secure and intuitive access to sales data for business teams.",
                    "how": "Crafted APIs in Go with AWS Lambda and DynamoDB, complemented by a TypeScript and Next.js web application.",
                    "impact": "Facilitated easier and safer access to sales data, improving decision-making capabilities and user experience for business teams."
                  },
                  {
                    "what": "Develop cost-effective sales analyzer",
                    "why": "To reduce operational costs associated with data analysis infrastructure for the client.",
                    "how": "Employed AWS Lambda, DynamoDB, and CloudWatch Events to create an efficient and cost-saving solution.",
                    "impact": "Dramatically reduced operational expenses while ensuring high performance, improving scalability and reliability of sales data analysis."
                  },
                  {
                    "what": "Provision infrastructure for serverless architecture on AWS",
                    "why": "To establish a cloud environment for the client, enhancing their technological capabilities.",
                    "how": "Set up VPC, IAM, Security Group, Session Manager, and VPC endpoints on AWS.",
                    "impact": "Created a solid cloud foundation, empowering the client to utilize cloud advantages for scalability, security, and resource management, and setting the stage for future cloud-based endeavors."
                  }
                ]
              }

            ],
            "tagTitle": "Technology Stack",
            "tags": {
              "Programming Language": ["Go", "TypeScript"],
              "Web Framework": ["Next.js", "MaterialUI", "React Query", "Storybook"],
              "Cloud provider": ["AWS"],
              "AuthN/AuthZ": ["Amazon Cognito User Pools", "Amazon Cognito Identity Pools"]
            }
          }
        }
      ]
    },
    {
      title: 'Public Exposure',
      isThisSectionVisible: !isResumeByPDF, // false for CV
      type: DATA_TYPE_PUBLIC_EXPOSURE,
      icon: 'comments',
      groups: [
        {
          sectionHeader: 'Talks',
          items: [
            {
              title: 'ソフトウェアテスト新時代の幕開け: 機械学習とデータサイエンスで実現するテスト運用の高度化',
              publishedAt: "Feb 9, 2023",
              projectUrl: 'https://event.shoeisha.jp/devsumi/20230209/session/4142/', description: 'A CFP accepted talk at Developers Summit 2023.'
            },
            {
              title: 'Sensible Incident Management for Software Startups',
              publishedAt: "May 15, 2022",
              projectUrl: 'https://sre-next.dev/2022/schedule/#jp42', description: 'A CFP accepted talk at SRE NEXT 2022.'
            },
            {
              title: 'Designing fault-tolerant microservices with SRE and circuit breaker centric architecture',
              publishedAt: "Jan 15, 2020",
              projectUrl: 'https://sre-next.dev/2020/schedule/#c6', description: 'A CFP accepted talk at SRE NEXT 2020.'
            },
            {
              title: 'Challenges for Global Service from a Perspective of SRE 2nd season',
              publishedAt: "Feb 27, 2019",
              projectUrl: 'https://techconf.cookpad.com/2019/takayuki_watanabe.html', description: 'A CFP accepted talk at Cookpad TechConf 2019.'
            },
            {
              title: 'Challenges for Global Service from a Perspective of SRE',
              publishedAt: "Feb 10, 2018",
              projectUrl: 'https://techconf.cookpad.com/2018/', description: 'A CFP accepted talk at Cookpad TechConf 2018.'
            },
          ]
        },
        {
          sectionHeader: 'Articles',
          items: [
            {
              title: 'Search at Cookpad: building new infrastructure, published',
              publishedAt: "Jul 23, 2021",
              projectUrl: 'https://sourcediving.com/search-at-cookpad-building-new-infrastructure-dc58f4eab93f', description: 'An article explaining how we built teams who own the full stack of application development and infrastructure components have improved communication and collaboration.'
            },
            {
              title: 'Synthetic Monitoring を活用したグローバルサービスのネットワークレイテンシの測定と改善',
              publishedAt: "Sep 21, 2017",
              projectUrl: 'https://techlife.cookpad.com/entry/2017/09/21/080000', description: 'An article explaining why latency is a problem for Cookpad\'s international services and how we improved the measurement of latency with Catchpoint.'
            },
          ]
        },
        {
          sectionHeader: 'Research Paper',
          items: [
            { title: 'Watanabe, T., Fujimoto, Y., & Hayashi, Y. (2013) “Verification of Loss Reduction Effect on Loss Minimum Configuration of Distribution System by Zero-Suppressed Binary Decision Diagram for Large Penetration of Residential PV.” 2013 IEEE PES Innovative Smart Grid Technologies Conference, ISGT 2013, 6497872.', projectUrl: 'ieeexplore.ieee.org/document/6497872/media', description: '' },
            { title: 'Watanabe, T., & Hayashi, Y. (2013) “Minimization of Transmission and Distribution Losses Using Hierarchy Control Method of Transmission System Circuit Breakers and Distribution System Switches for Various PV Penetration Cases.” IEEJ Transactions on Power and Energy 133, pp. 383–395.', projectUrl: 'https://ci.nii.ac.jp/naid/10031161260/en/', description: '' },
          ]
        },
      ]
    },
    {
      title: COMMON_LIST_TITLE_EDUCATION,
      isThisSectionVisible: !isResumeByPDF, // false for CV
      type: DATA_TYPE_COMMON_LIST,
      icon: 'graduation',
      detailedPagePath: "/education",
      items: [
        {
          isThisItemVisible: true, // always show this section
          title: 'M.E, Department of Electrical Engineering and Biosciences',
          authority: 'Waseda University, Tokyo, Japan',
          authorityWebSite: 'http://www.eb.waseda.ac.jp',
          rightSide: 'April 2011 - March 2013',
          description: 'Achieved a GPA of 4.0/4.0 while pursuing majors in both computer science and electrical engineering.',
          details: {
            descriptions: [
              "Attained a perfect GPA of 4.0/4.0 while pursuing dual majors in computer science and electrical engineering.",
              "Conducted research for the Private University Strategic Research Infrastructure Formation Support Project, a collaboration between the Ministry of Education, Culture, Sports, Science, and Technology (MEXT) and the Graduate School of Advanced Science and Engineering, spanning from April 2011 to March 2013.",
              "Recognized as a recipient of the Scholarship in Graduate School of Electronic and Electrical Engineering, awarded in recognition of outstanding academic performance and research achievements during the first two years of the master's degree program at Waseda University. This scholarship was contingent upon maintaining exceptional performance in all studies and research activities.",
            ],
          },
        },
        {
          isThisItemVisible: true, // always show this section
          title: 'MOT Leader course, School of Business Design and Management',
          authority: 'Waseda University, Tokyo, Japan',
          authorityWebSite: 'https://www.bdm.waseda.ac.jp',
          rightSide: 'April 2011 - March 2013'
        },
        {
          isThisItemVisible: true, // always show this section
          title: 'B.E, Department of Electrical Engineering and Biosciences',
          authority: 'Waseda University, Tokyo, Japan',
          authorityWebSite: 'http://www.eb.waseda.ac.jp',
          rightSide: 'April 2007 - March 2011',
          description: 'Achieved a GPA of 3.8/4.0 with 181 credits, surpassing the standard graduation criteria of the department by approximately 50 credits. Majored in both computer science and electrical engineering.',
          details: {
            descriptions: [
              "Earned a GPA of 3.8/4.0 with 181 credits, surpassing the standard graduation criteria of the department by approximately 50 credits. Majored in both computer science and electrical engineering.",
              "Honored with the Tamura Award in 2011 for outstanding research contributions characterized by novelty and creativity.",
              "Received the Young Engineer Poster Competition 2010 Award, recognizing the research paper and presentation as one of the highest-rated in the IEEJ (Institute of Electrical Engineers of Japan) conference.",
            ],
          },
        }
      ]
    },
    {
      title: 'Internship',
      isThisSectionVisible: !isResumeByPDF, // false for CV
      type: DATA_TYPE_EXPERIENCE_LIST,
      // description: 'Optional',
      icon: 'archive',
      items: [
        {
          isThisItemVisible: true, // always show this section
          title: 'Reasearch Assistant',
          company: 'ERATO Minato Discrete Structure Manipulation System Project',
          description: 'Conducted research and developed loss minimization methods for a large-scale distribution network as a research assistant for the Minato Discrete Structure Manipulation System Project at Japan Science and Technology Agency Exploratory Research for Advanced Technology. Outcomes are summarized at https://www.waseda.jp/top/news/9050.',
          companyWebSite: 'https://www.jst.go.jp/erato/minato/index_en.html',
          datesBetween: 'July 2010 - March 2013',
          descriptionTags: ['C++', 'Python', 'MATLAB', 'Power Network', 'Circuit Breaker', 'Zero-suppressed Binary Decision Diagram', 'Frontier-based search']
        },
        {
          isThisItemVisible: true, // always show this section
          title: 'Intern consultant',
          company: 'Boston Consulting Group',
          description: 'Engaged in companies’new business planning as a consultant for the Boston Consulting Group.',
          companyWebSite: 'https://www.bcg.com/',
          datesBetween: 'February 2012 - March 2012',
        },
        {
          isThisItemVisible: true, // always show this section
          title: 'Intern iOS application developer',
          company: 'NSOFT Inc.',
          description: 'Developed iOS applications in Japanese, English and Korean as an iOS Developer for NSOFT Inc.',
          datesBetween: 'April 2010 - April 2011',
          descriptionTags: ['Objective-C', 'Augmented reality']
        }
      ]
    },
    {
      title: COMMON_LIST_TITLE_LANGUAGES,
      isThisSectionVisible: !isResumeByPDF, // false for CV
      type: DATA_TYPE_COMMON_LIST,
      icon: 'language',
      items: [
        {
          isThisItemVisible: true, // always show this section
          authority: 'Japanese',
          authorityMeta: 'Native'
        },
        {
          isThisItemVisible: true, // always show this section
          authority: 'English',
          authorityMeta: 'Intermediate',
          description: 'Have been using in business more than 5 years. / Studied English at Robson College in Vancouver. / TOEFL 104 / Passed an adminssion exam for the Master of Software Engineering at Carnegie Mellon University (2019).'
        },
        {
          isThisItemVisible: true, // always show this section
          authority: 'German',
          authorityMeta: 'Beginner',
          description: 'Studied for 5 years but have no experiences in business.'
        },
        {
          isThisItemVisible: true, // always show this section
          authority: 'Spanish',
          authorityMeta: 'Beginner',
          description: 'Studied for 2 years but have no experiences in business.'
        }
      ]
    },
  ]
}
