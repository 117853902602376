import React from 'react';
import Alert from '@mui/material/Alert';
import Styles from '../../css/styles.module.css';

export function CalloutGuideForMoreInfo() {
  return (
    <Alert severity="info" className={Styles.aboutThisResume}>
      This PDF focuses on my most recent experiences and the technical skills I employ on a daily basis, enabling you to reduce time for the evaluation process for your convenience. For a comprehensive overview of my past experiences, public engagements, and educational background, please refer to <a href='https://takanabe.tokyo'>https://takanabe.tokyo</a>
    </Alert>
  );
}
