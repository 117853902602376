import { CVRoot } from './Components/Base/CVRoot'
import { CVData as LoadedData } from './data';

function App() {
  return (
    <div>
      <CVRoot {...LoadedData} />
    </div>
  );
}

export default App;
