import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Styles from '../../css/styles.module.css';
import BulmaCSS from '../../css/bulma.module.sass'
import Section from './Section';
import mergeClassNames from 'classnames'

export default class AboutThisCV extends React.Component {
  static propTypes = {
    isThisSectionVisible: PropTypes.bool,
    title: PropTypes.string,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.shape({})
  }

  render() {
    const { isThisSectionVisible, title, descriptions, icon } = this.props;
    if (!isThisSectionVisible) {
      return null
    }

    return (
      <>
        <Section
          title={title}
          icon={icon}
        >
            <div className={mergeClassNames(BulmaCSS.content, Styles.avoidBreakingOnPrint)}>
                <ul>
              {descriptions.map((d, i) => <li key={i}><b>{d.title}:</b> {d.content}</li>) }
                </ul>
            </div>
        </Section>
      </>
    );
  }
}
